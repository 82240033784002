import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { func, number } from "prop-types";
import { styled } from "@mui/system";
import { APP_NAME , BASE_IMAGE } from "../../../config/main.json";
import UserMenu from "./UserMenu";
import BreadCrumbs from "./BreadCrumbs";
import { useSelector } from "react-redux";

const logo = `${BASE_IMAGE}logo-dark.png`;

const Logo = styled("img")({});

const AccountToolbar = ({ drawerWidth, handleDrawerToggle }) => {
  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            marginRight: 2,
            display: {
              md: "none !important",
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Logo
          src={logo}
          alt={APP_NAME}
          sx={{
            display: {
              md: "none",
              xs: "inline-block",
            },
            height: {
              xs: "35px !important",
              md: "initial",
            },
            margin: {
              xs: "14px 0px",
              md: "30px",
            },
          }}
        />
        <Typography component="div" sx={{ flexGrow: 1 }}>
        <BreadCrumbs />
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          {/* Uncomment the following lines if you need to display badges */}
          {/* 
          <Badge badgeContent={4} color="error">
            <MailIcon color="action" />
          </Badge>
          <Badge
            color="error"
            overlap="circular"
            badgeContent=" "
            variant="dot"
          >
            <NotificationsNoneIcon sx={{ color: "#414141" }} />
          </Badge>
          <SettingsIcon color="action" />
          */}
          <UserMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

AccountToolbar.propTypes = {
  handleDrawerToggle: func.isRequired,
  drawerWidth: number.isRequired,
};

export default AccountToolbar;
