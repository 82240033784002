import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

/* external files */
import { API_HOST } from "../../config/main.json";

/* component external files */
import enums from "./enums";
import LinkFilter from "./LinkFilter";
import LinkUrl from "./LinkUrl";
import LinkAnalysisChart from "./LinkAnalysisChart";
import LinkLocations from "./LinkLocations";
import helper from "./helper";
import countryCodes from "./countryCodes";
import slides from "../../common/googleSlides.png";

/* mui */
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import Datatables from "mui-datatables";
import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";
import { Analytics, Download, ManageAccounts, ManageSearch } from "@mui/icons-material";
import { connect } from "react-redux";
import GeneratePptReport from "../generatePpt/GeneratePptReport";
import CampaignActions from "../../pages/account/CampaignActions";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

let tabArray;
class LinkAnalysis extends Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    tabArray = campaignTabsArray(campId);
    this.state = {
      campId: campId,
      linkToAddPost: "/campaigns/addPost?id=" + campId,
      lists: [],
      influencers: [],
      urls: [],
      selectedUrlId: [],
      totalClicksData_daily: [],
      totalClicksData_cumulative: [],
      defaultStyles: {},
      linkInfo_tableData: [],
      linkInfo_tableOptions: {},
      linkInfo_tableColumns: [],
      location_tableOptions: {},
      countryLocation_tableColumns: [],
      cityLocation_tableColumns: [],
      countryLocationDataForTable: [],
      cityLocationDataForTable: [],
      startDate: moment().subtract(29, "days").toDate(),
      endDate: moment().toDate(),
      campaignName: "",
      campaignStart: "",
      campaignEnd: "",
      showLinksFilter: false,
      selectedUrls: [],
      notification: false,
      type: "",
      message: "",
      pptReportId: 0,
    };
  }

  componentDidMount() {
    this.loadDefaultStyles();
    this.getCampaignInfo();
  }

  loadDefaultStyles = () => {
    const defaultStyles = enums.defaultStyles;
    const defaultMenu = enums.defaultMenu;
    this.setState({ defaultStyles, defaultMenu });
  };

  getAllUrls = (lids, urlIds, platform) => {
    let cid = -1;
    if (platform === "all") {
      cid = parseInt(this.state.campId);
    }
    const payload = {
      cid: cid,
      lids: lids,
      urlIds: urlIds,
    };
    axios.post(API_HOST + "/link/filter", payload, optionAxios).then((res) => {
      let urls = res.data ? res.data : [];
      if (urls.length > 0) {
        const selectedUrlIds = urls.map((url) => url.id);
        this.getUrlData(selectedUrlIds, this.state.startDate, this.state.endDate, urlIds);
      } else {
        this.setState({
          totalClicksData_daily: { labels: [], datasets: [] },
          totalClicksData_cumulative: { labels: [], datasets: [] },
          devicesData: { labels: [], datasets: [] },
          referrersData: { labels: [], datasets: [] },
          locationData: [],
          countryLocationDataForTable: [],
          cityLocationDataForTable: [],
          linkInfo_tableData: [],
        });
      }
      this.setState({ urls, selectedUrls: urls.map((url) => url.id) });
    });
  };

  getUrlData = (shortUrlIds, startDate, endDate, urlIds) => {
    const sDate = moment(startDate).format().split("T")[0];
    const eDate = moment(endDate).format().split("T")[0];
    this.getTotalClicksData(shortUrlIds, sDate, eDate);
    this.getDevicesData(shortUrlIds, sDate, eDate);
    this.getReferrersData(shortUrlIds, sDate, eDate);
    this.getCountryLocationData(shortUrlIds, sDate, eDate);
    this.getCityLocationData(shortUrlIds, sDate, eDate);
    this.getLinkInfo(shortUrlIds, sDate, eDate, urlIds);
  };

  getCampaignInfo() {
    const campaignId = this.state.campId;
    utils.getCampaignData(campaignId).then((data) => {
      this.setState({
        campaignName: data.name,
        campaignStart: data.startDate.split("T")[0],
        campaignEnd: data.endDate.split("T")[0],
        showLinksFilter: true,
      });
    });
  }

  getTotalClicksData = (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };

    axios.post(API_HOST + "/link/analysis/clicks/summary", payload, optionAxios).then((res) => {
      const totalClicksData = res.data;
      const totalClicksData_daily = helper.restructureDataForChart(totalClicksData["daily"], "bar", "daily", 1, "#4FD1C5");
      const totalClicksData_cumulative = helper.restructureDataForChart(
        totalClicksData["cumulative"],
        "bar",
        "cumulative",
        1,
        "#4FD1C5"
      );
      this.setState({ totalClicksData_daily, totalClicksData_cumulative });
    });
  };

  getDevicesData = (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };

    axios.post(API_HOST + "/link/analysis/devices", payload, optionAxios).then((res) => {
      const devicesData = helper.restructureDataForChart(res.data, "doughnut", "Devices");
      this.setState({ devicesData });
    });
  };

  getReferrersData = (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };

    axios.post(API_HOST + "/link/analysis/referrers", payload, optionAxios).then((res) => {
      const referrersData = helper.restructureDataForChart(res.data, "bar", "Referrers", true);
      this.setState({ referrersData });
    });
  };

  getCountryLocationData = (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    const location_tableOptions = enums.location_tableOptions;
    const countryLocation_tableColumns = enums.countryLocation_tableColumns;
    axios.post(API_HOST + "/link/analysis/countries", payload, optionAxios).then((res) => {
      const locationData = helper.restructureCountryDataForMap(res.data);
      const countryLocationDataForTable = helper.remapCountryLocationDataForTable(locationData, countryCodes);
      this.setState({
        locationData,
        countryLocationDataForTable,
        location_tableOptions,
        countryLocation_tableColumns,
      });
    });
  };

  getCityLocationData = (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    const location_tableOptions = enums.location_tableOptions;
    const cityLocation_tableColumns = enums.cityLocation_tableColumns;
    axios.post(API_HOST + "/link/analysis/cities", payload, optionAxios).then((res) => {
      const locationData = helper.restructureCityDataForMap(res.data);
      const cityLocationDataForTable = helper.remapCityLocationDataForTable(locationData, countryCodes);
      this.setState({
        cityLocationDataForTable,
        location_tableOptions,
        cityLocation_tableColumns,
      });
    });
  };

  getLinkInfo = (shortUrlIds, startDate, endDate, urlIds) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    let linkInfo_tableOptions, linkInfo_tableColumns;
    if (urlIds.length <= 1) {
      linkInfo_tableOptions = enums.linkInfo_tableOptions;
      linkInfo_tableColumns = enums.linkInfo_tableColumns;
    } else {
      linkInfo_tableOptions = enums.linkInfo_multiTableOptions;
      linkInfo_tableColumns = enums.linkInfo_multiCableColumns;
    }
    axios.post(API_HOST + "/link/analysis/basic", payload, optionAxios).then((res) => {
      let linkInfo_tableData = [];
      if (urlIds.length <= 1) {
        const data = res.data ? res.data : [];
        linkInfo_tableData = data.map((row) => {
          return {
            influencerNameAndUsername: `${row.influencerName} ${row.userName ? "(@" + row.userName + ")" : ""}`,
            ...row,
          };
        });
      } else {
        const tableData = res.data ? res.data : [];
        const destinationUrlObject = {};
        tableData.forEach((row) => {
          if (!destinationUrlObject[row.baseDestinationId]) {
            destinationUrlObject[row.baseDestinationId] = [];
          }
          destinationUrlObject[row.baseDestinationId].push(row);
        });
        Object.keys(destinationUrlObject).forEach((id) => {
          const table = {};
          table.destinationId = id;
          table.destinationUrl = destinationUrlObject[id][0].baseDestinationUrl;
          table.group = {
            platform: destinationUrlObject[id][0].platform,
            list: destinationUrlObject[id][0].listName,
          };
          let totalClicks = 0;
          const data = [];
          destinationUrlObject[id].forEach((row) => {
            totalClicks += row.clicks;
            row.links = {
              short: row.shortUrl,
              destination: row.destinationUrl,
            };
            row.influencerNameAndUsername = (
              <a href={row.profileUrl} target="_blank" rel="noopener noreferrer">
                {`${row.influencerName} ${row.userName ? "(@" + row.userName + ")" : ""}`}
              </a>
            );
            data.push(row);
          });
          table.expansion = (
            <TableRow
              sx={{
                ".MuiPaper-root": {
                  boxShadow: "none",
                },
              }}
              style={{
                backgroundColor: "#f6f1f1",
                width: "90%",
                alignContent: "center",
              }}
            >
              <TableCell colSpan={12} sx={{ padding: "0px 0px 0px 24px" }}>
                <Datatables data={data} columns={enums.subTableColumns} options={enums.subTableOptions} />
              </TableCell>
            </TableRow>
          );
          table.totalClicks = totalClicks;
          table.data = data;
          linkInfo_tableData.push(table);
        });
      }
      this.setState({
        linkInfo_tableData,
        linkInfo_tableOptions,
        linkInfo_tableColumns,
        selectedUrlId: urlIds,
      });
    });
  };

  /* filter */
  handleFilterChange = (filterOn) => {
    const lids = filterOn.lids;
    const urlIds = filterOn.urlIds;
    const platform = filterOn.platform;

    this.getAllUrls(lids, urlIds, platform);
  };

  handleUrlChange = (data) => {
    const urlIds = data.urlIds;
    const startDate = data.startDate;
    const endDate = data.endDate;
    this.setState({
      selectedUrls: urlIds,
      startDate: startDate,
      endDate: endDate,
    });
    this.getUrlData(urlIds, startDate, endDate, this.state.selectedUrlId);
  };

  downloadData = (event) => {
    const downloadAs = event.target.value;
    const today = new Date();
    const filename = "clicks_analysis-" + today.getDate() + (today.getMonth() + 1) + today.getFullYear() + "." + downloadAs;
    let input = document.getElementById("link-analysis");
    if (downloadAs === "pdf") {
      const w = input.offsetWidth;
      const h = input.offsetHeight;
      html2canvas(input).then((canvas) => {
        const img = canvas.toDataURL("image/jpeg", 1);
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const doc = new jsPDF("p", "px", [w, h]);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
        doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.save(filename);
      });
    } else if (downloadAs === "jpeg") {
      html2canvas(input).then((canvas) => {
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = filename;
        a.click();
      });
    } else if (downloadAs === "png") {
      html2canvas(input, { allowTaint: false, useCORS: true }).then((canvas) => {
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = filename;
        a.click();
      });
    }
  };

  sendXlsx = () => {
    const email = this.props.user.attributes.email;
    const payload = {
      ShortUrlIds: this.state.selectedUrls,
      startDate: moment(this.state.startDate).format().split("T")[0],
      endDate: moment(this.state.endDate).format().split("T")[0],
      email: email,
    };

    axios.post(API_HOST + "/link/analysis/sendxlsx", payload, optionAxios).then((res) => {
      if (res.data === "success") {
        this.setState({
          notification: true,
          type: "success",
          message: `File sent to ${email}!`,
        });
      }
    });
  };

  closeNotification = () => {
    this.setState({ notification: false });
  };

  render() {
    const tabArray2 = [
      {
        label: "Metrics",
        id: "Metrics-tab-0",
        to: `/campaigns/linkAnalysis?id=${this.state.campId}`,
        component: Link,
        icon: <Analytics />,
        iconPosition: "start"
      },
      {
        label: "Manage Destination URLs",
        id: "manage-destination-tab-0",
        to: `/campaigns/linkAnalysis/urls?id=${this.state.campId}`,
        component: Link,
        icon: <ManageAccounts />,
        iconPosition: "start"
      },
    ]

    return (
      <>
        <CampaignActions name={this.state.campaignName} id={this.state.campId} />
        <br />
        <CustomTabs tabArray={tabArray} selectedTab={"4"} />

        <div id="link-analysis-component">
          <Box m={3} style={{ paddingBottom: "35px", marginTop: "0px" }}>
            <CustomTabs tabArray={tabArray2} selectedTab={"0"} />
          </Box>
          <Stack direction="row" justifyContent="space-between">
            <Box></Box>
            <Box>
              <FormControl size="small" sx={{ marginRight: "70px" }}>
                <InputLabel id="export_as-select-label">Export As</InputLabel>
                <Select
                  sx={{ minWidth: "8vw" }}
                  style={{ color: "black" }}
                  labelId="export_as-select-label"
                  id="export_as-select"
                  label="Export As"
                  value={""}
                  onChange={this.downloadData}
                  variant="outlined"
                  autoWidth
                >
                  <MenuItem value={"png"}>PNG</MenuItem>
                  <MenuItem value={"jpeg"}>JPEG</MenuItem>
                  <MenuItem value={"pdf"}>PDF</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <br />
          {/* filter box */}
          <LinkFilter campId={this.state.campId} handleFilterChange={this.handleFilterChange} />

          {/* URL - name and new addition */}
          {this.state.showLinksFilter && (
            <LinkUrl
              urls={this.state.urls}
              handleUrlChange={this.handleUrlChange}
              start={this.state.campaignStart}
              end={this.state.campaignEnd}
            />
          )}

          {/* Graphs and Charts */}
          <div id="link-analysis">
            <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
              {/* Total clicks */}
              <Grid item xs={4} md={12}>
                {this.state.totalClicksData_daily && (
                  <LinkAnalysisChart
                    isToggle={true}
                    data={this.state.totalClicksData_daily}
                    additionalData={this.state.totalClicksData_cumulative}
                    styles={this.state.defaultStyles}
                    chartTitle={"Total clicks"}
                  />
                )}
              </Grid>

              {/* Devices */}
              <Grid item xs={4} md={6}>
                {this.state.devicesData && (
                  <LinkAnalysisChart
                    isToggle={false}
                    data={this.state.devicesData}
                    styles={this.state.defaultStyles}
                    chartTitle={"Devices"}
                    chartType={"doughnut"}
                    chartOptions={{
                      legend: { display: true, position: "right" },
                    }}
                  />
                )}
              </Grid>

              {/* Referrers */}
              <Grid item xs={4} md={6}>
                {this.state.referrersData && (
                  <LinkAnalysisChart
                    isToggle={false}
                    data={this.state.referrersData}
                    styles={this.state.defaultStyles}
                    chartTitle={"Referrers"}
                    chartOptions={{ indexAxis: "y" }}
                  />
                )}
              </Grid>

              {/* Locations */}
              <Grid item xs={4} md={12}>
                <LinkLocations
                  parentStyle={this.state.defaultStyles}
                  color="green"
                  title="Locations"
                  size="xl"
                  data={this.state.locationData}
                  cityTableData={this.state.cityLocationDataForTable}
                  countryTableData={this.state.countryLocationDataForTable}
                  cityTableCols={this.state.cityLocation_tableColumns}
                  countryTableCols={this.state.countryLocation_tableColumns}
                  tableOpts={this.state.location_tableOptions}
                />
              </Grid>

              {/* Table */}
              <Grid item xs={4} md={12}>
                <MyPaper sx={{ marginTop: "10px" }}>
                  <Stack direction="row" justifyContent="end" sx={{ marginBottom: "10px" }}>
                    <Typography variant="h4" sx={this.state.defaultStyles.typography}>
                      Data Table
                    </Typography>
                    <Button variant="contained" startIcon={<Download />} style={{ height: "40px" }} onClick={this.sendXlsx}>
                      Download .xlsx
                    </Button>
                  </Stack>
                  <Datatables
                    data={this.state.linkInfo_tableData}
                    columns={this.state.linkInfo_tableColumns}
                    options={this.state.linkInfo_tableOptions}
                  />
                </MyPaper>
              </Grid>
            </Grid>
          </div>
        </div>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={3000}
          onClose={this.closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.closeNotification} severity={this.state.type} variant="filled" sx={{ width: "100%" }}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

function mapStateToProps(state) {
  const user = state.user;
  return { user };
}

export default connect(mapStateToProps)(LinkAnalysis);
