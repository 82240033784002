import axios from "axios";
import { API_HOST, PROFILE_IMG, DEFAULT_POST_THUMBNAIL, LOCALAPI_HOST } from "../config/main.json";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";

import Typography from "@mui/material/Typography";

/* post history timeline */
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import moment from "moment";
import { FormLabel, FormGroup } from "@material-ui/core";
import { Grid, TextField, Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import DOMPurify from "dompurify";
import { ViewAgenda } from "@material-ui/icons";
import { RemoveRedEye } from "@mui/icons-material";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const utils = {
  /* get all primary categories */
  getPcatData: () => {
    return axios.get(API_HOST + "/brand/pcat").then((res) => {
      return res.data;
    });
  },

  /* get all secondary categories */
  getScatData: () => {
    return axios.get(API_HOST + "/brand/scat").then((res) => {
      return res.data;
    });
  },

  /* get details of active accounts */
  getCompanyData: () => {
    return axios.get(`${API_HOST}/account/list/type/1/all`).then((res) => {
      return res.data;
    });
  },

  /* get all brand details, given particular accId */
  getBrandData: (accId) => {
    return axios.get(API_HOST + "/brand/list/accId/" + accId).then((res) => {
      return res.data;
    });
  },

  /* get all brand details */
  getAllBrandData: (type, platform) => {
    return axios.get(`${API_HOST}/brand/list/${type}/${platform}`).then((res) => {
      return res.data;
    });
  },

  getBrandDetails: (id) => {
    return axios.get(`${API_HOST}/brand/list/id/${id}`).then((res) => {
      return res.data;
    });
  },

  /* get all business types */
  getBtData: () => {
    return axios.get(API_HOST + "/business/types").then((res) => {
      return res.data;
    });
  },

  /* get all membership details */
  getMembershipDetails: (id) => {
    return axios.get(API_HOST + "/business/membership/" + id).then((res) => {
      return res.data;
    });
  },

  /* get all countries */
  getAllCountries: () => {
    return axios.get(API_HOST + "/countries").then((res) => {
      return res.data;
    });
  },


  /* get all the plans  */
  getAllThePlans: () => {
    return axios.get(API_HOST + "/business/membership/all").then((res) => {
      return res.data;
    });
  },

  /* get all agency's client */
  getAgencyClientList: (id) => {
    return axios.get(API_HOST + "/account/client/" + id).then((res) => {
      return res.data;
    });
  },

  /* get all business attributes */
  getAttrData: () => {
    return axios.get(API_HOST + "/brand/attrs").then((res) => {
      return res.data;
    });
  },


  /* get campaign details, gived campaing id */
  getCampaignData: (campId) => {
    return axios.get(`${API_HOST}/campaign/id/${campId}`).then((res) => {
      return res?.data[0];
    });
  },

  /* get Task list details, gived campaing id */
  getTaskListData: (campId) => {
    return axios.get(`${API_HOST}/taskList/cid/${campId}`).then((res) => {
      return res.data;
    });
  },

  /* get tag types details, gived campaing id */
  getTagTypeData: (campId) => {
    return axios.get(`${API_HOST}/campaign/postType/${campId}`).then((res) => {
      return res.data;
    });
  },

  /* get status details, gived campaing id */
  getStatusData: (campId) => {
    return axios.get(`${API_HOST}/campaign/status/${campId}`).then((res) => {
      return res.data;
    });
  },

  getCampaignSumary: (campId) => {
    return axios.get(`${API_HOST}/campaignSummary/id/${campId}`).then((res) => {
      return res.data;
    });
  },

  getAccountSumary: (campId) => {
    return axios.get(`${API_HOST}/account/${campId}/dashboard`).then((res) => {
      return res.data;
    });
  },

  createAgencyClient: (body) => {
    return axios
      .post(API_HOST + "/account/client/add", body, optionAxios)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  changeMembership: (body) => {
    return axios
      .post(API_HOST + "/account/membership/edit", body, optionAxios)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get phases of each list in a campaign, given campaign id */
  getNoOfphases: (campId) => {
    return axios
      .get(API_HOST + "/campaign/analysis/phases/" + campId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get top n (defined in backend - by default, 10) influencers in a campaign */
  getTopNInfluencers: (campId) => {
    return axios
      .get(API_HOST + "/topInfluencers/campaignid/" + campId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get top n (defined in backend - by default, 10) posts in a campaign */
  getTopNPosts: (campId) => {
    return axios.get(API_HOST + "/topPosts/campaignId/" + campId).then((res) => {
      return res.data;
    });
  },

  /* get analysis data for all influencers in a particular campaign */
  getInfluencersAnalysis: (
    campId,
    platform,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    IsExport,
    isRefreshed,
    mimeType,
    account,
    client,
    timeZone,
    lastPostedStatus
  ) => {
    return axios
      .post(
        `${API_HOST}/campaign/influencers`,
        {
          cid: parseInt(campId),
          plid: platform === -1 ? [1, 2, 3, 4, 5, 6] : [platform],
          lids: [-1],
          page: tablePageNo || 1,
          rows: tableRowsPerPage || 100,
          SortCol: sortCol || "lastPosted",
          SortOrder: sortOrder || "desc",
          isExport: IsExport || 0,
          isRefreshed: isRefreshed || false,
          mimeType: mimeType || "",
          campaignName: "",
          account: account || 0,
          client: client || 0,
          timeZone: timeZone || "Asia/Calcutta",
          lastPostedStatus: lastPostedStatus || "All",
        },
        optionAxios
      )
      .then((res) => {

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get platform-wise influencer count for a particular campaign, given campaign id, start date, and end date */
  getNoOfInfluencers: (campId, sd, ed) => {
    return axios
      .post(
        API_HOST + "/campaign/analysis/influencersCount",
        {
          cid: campId,
          startDate: sd,
          endDate: ed,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* given platform-wise influencer details (id, name, username, followers) for a particular campaign, given campaign id and platform */
  getAllInfluencers: (lids, platform, cid) => {
    return axios
      .post(
        API_HOST + "/list/influencers/name",
        {
          lids,
          platform,
          cid,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get number of posts for a particular campaign in specific time frame, given campaign id, start date, and end date */
  getNoOfPosts: (campId, sd, ed) => {
    return axios
      .post(
        API_HOST + "/campaign/analysis/postsCount",
        {
          cid: campId,
          startDate: sd,
          endDate: ed,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get platform-wise data for campaign snapshot */
  getCampaignSnapshotStats: (campId, sd, ed, filter, platformId, postTypes, lids, iids) => {
    return axios
      .post(
        API_HOST + "/campaign/analysis/snapshot",
        {
          cid: campId,
          from: sd,
          to: ed,
          filterby: filter,
          plid: platformId,
          postTypes: postTypes,
          lids: lids,
          iids: iids,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get platform-wise influencer stats (follower count, and post count) for a particular campaign, given campaign id and platform */
  getInfluencerSnapshotStats: (campId, platform) => {
    return axios
      .post(
        API_HOST + "/influencer/analysis/snapshot",
        {
          cid: parseInt(campId),
          plid: platform,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get list-wise stats (likes, comments, etc) for analysis */
  getPhaseAnalysisStats: (cid, sd, ed, filter, platformId, postTypes, lids, iids) => {
    return axios
      .post(
        API_HOST + "/orca/stats/cid/listwise",
        {
          cid: cid,
          from: sd,
          to: ed,
          filterBy: filter,
          plid: platformId,
          postTypes: postTypes,
          lids: lids,
          iids: iids,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get platform-wise stats of a particular campaign */
  getTotalEngagementData: (cid, sd, ed, filter, platformId, postTypes, lids, iids, tags) => {
    platformId = platformId === -1 ? [1, 2, 3, 4, 5, 6] : [platformId];

    return axios
      .post(
        API_HOST + "/campaign/engagement",
        {
          cid,
          from: sd,
          to: ed,
          filterBy: filter,
          plid: platformId,
          postTypes,
          lids,
          iids,
        },
        optionAxios
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  },


  /* get engagement and reach by post type for a particular campaign */
  getEngReachByPostType: (cid, sd, ed, filter, platformId, postTypes, lids, iids) => {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return axios
      .post(
        API_HOST + "/orca/stats/byPostType",
        {
          cid: cid,
          from: sd,
          to: ed,
          filterBy: filter,
          plid: platformId,
          postTypes: postTypes,
          lids: lids,
          iids: iids,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get audience report */
  getAudienceReport: (campId) => {
    return axios.get(API_HOST + "/campaign/audienceReport/" + campId).then((res) => {
      return res.data;
    });
  },

  /* get account details, given account id */
  getAccountData: (accId) => {
    return axios.get(API_HOST + "/account/id/" + accId).then((res) => {
      return res.data[0];
    });
  },

  /* get details of all audiences */
  getAudienceListData: () => {
    return axios.get(API_HOST + "/lists/type/1/all").then((res) => {
      return res.data;
    });
  },

  /* date conversion */
  dateConversion(dt, type) {
    if (type === "custom") {
      if (dt.includes("T")) {
        dt = dt.replace("T", " ") + ":00";
      }
      return dt;
    }
    const curr_date = dt.getDate();
    let curr_month = dt.getMonth();
    curr_month = curr_month + 1;
    const curr_year = dt.getFullYear();
    const curr_min = dt.getMinutes();
    const curr_hr = dt.getHours();
    const curr_sc = dt.getSeconds();
    return curr_year + "-" + curr_month + "-" + curr_date + " " + curr_hr + ":" + curr_min + ":" + curr_sc;
  },

  /* get data of a particular campaign, given account id */
  getCampaignDataById: (id) => {
    return axios.get(API_HOST + "/account/list/id/" + id).then((res) => {
      return res.data;
    });
  },

  /* get all campaigns associated with influencer */
  getInfluencerCampaignMapping: (iids) => {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return axios.post(API_HOST + "/campaign/influencer/map", { iids: iids }, optionAxios).then((res) => {
      return res.data;
    });
  },

  getPostData: (data, accId) => {
    let url = "/brand/edit/" + accId;
    return axios.post(API_HOST + url, data, optionAxios).then((res) => {
      return res;
    });
  },

  /* get start date and end date of a week, given week number */
  getStartEndDateOfWeek(w) {
    let y = new Date().getFullYear();
    let simple = new Date(y, 0, 1 + (w - 1) * 7);
    let dow = simple.getDay(); // day of week
    let ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    const ISOweekEnd = new Date(ISOweekStart.getTime() + 6 * 24 * 60 * 60 * 1000);
    return ISOweekStart.toISOString().split("T")[0] + " To " + ISOweekEnd.toISOString().split("T")[0];
  },

  /* get start and end date of the month, given month number */
  getStartEndDateOfMonth(m) {
    let y = new Date().getFullYear();
    let lastDay = new Date(y, m).toISOString().split("T")[0];
    const tempArr = lastDay.split("-");
    tempArr[2] = "01";
    let startDay = tempArr.join("-");
    return startDay + " To " + lastDay;
  },

  /* number conversion based on international number system */
  convertToInternationalNumberSystem(labelValue, fixedPointOffset = 2) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(fixedPointOffset) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(fixedPointOffset) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(fixedPointOffset) + "K"
          : Math.abs(Number(labelValue));
  },

  /* converting div to pdf using "html2canvas" */
  div2PDF: (id, fileName) => {
    let input = document.getElementById(id);

    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(img, "png", 0, 0, pdfWidth, pdfHeight);
      pdf.save(fileName + ".pdf");
    });
  },

  /* download a particular image using "base64image" */
  downloadImage(fileType, extension, fileName, chartRef) {
    const base64Image = chartRef.toBase64Image("image/" + fileType, 1.0);
    saveAs(base64Image, fileName + "." + extension);
  },

  /* return a string after capitalizing the first letter of given string */
  capitalizeFirstLetter(str, keepRemainingLowerCase = true) {
    const wordsArray = keepRemainingLowerCase ? str.toLowerCase().split(/\s+/) : str.split(/\s+/);
    const upperCased = wordsArray.map(function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1);
    });
    return upperCased.join(" ");
  },

  /* check refresh status of a aprticular campaign, given campaign id */
  checkRefreshStatusSource(cid) {
    if (!!window.EventSource) {
      return new EventSource(API_HOST + "/orca/postpendingstatus/cid/" + cid);
    }
  },

  /* refreshing analytics data of a particular campaign, given campaign id */
  refreshAnalyticsData(cid) {
    return axios.get(API_HOST + "/orca/refresh/cid/" + cid).then((res) => {
      return res.data;
    });
  },

  /* get list of all platforms */
  getPlatforms: () => {
    return axios
      .get(API_HOST + "/platform/list")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* get platform-wise posts stats (engagement, reach etc) in a particular list for given influencers */
  getPostsList: (
    campId,
    lid,
    selectedPlatform,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    isExport,
    filter,
    sd,
    ed,
    mimeType,
    campaignName,
    account,
    client,
    timeZone,
    selectedTasksIds,
    milestoneIds,
    deleteVlue,
    selectedInfluencersIds,
    selectedStatus,
    selectedTagType
  ) => {
    selectedStatus = selectedStatus === -1 ? "" : selectedStatus
    return axios
      .post(
        `${API_HOST}/campaign/post`,
        {
          cid: campId,
          plid: selectedPlatform == -1 ? [1, 2, 3, 4, 5, 6] : [selectedPlatform],
          lids: lid == 0 ? [-1] : lid,
          iids: selectedInfluencersIds,
          page: tablePageNo,
          rows: tableRowsPerPage,
          SortCol: sortCol,
          sortOrder,
          isExport,
          filter,
          startDate: sd,
          endDate: ed,
          mimeType,
          campaignName,
          account,
          client,
          timeZone: [timeZone],
          taskIds: selectedTasksIds,
          milestoneIds: [-1],
          deleted: deleteVlue,
          secondaryFilter: {
            status: selectedStatus, // add this only if a status is selected from above
            postTypes: selectedTagType //only if the ids are selected from above
          }
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getDraftList: (
    campId,
    status,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    selectedPlatformName,
    selectedListIds,
    selectedInfluencersIds,
    selectedTasksIds
  ) => {
    return axios
      .post(
        `${API_HOST}/posts/drafts`,
        {
          cid: campId,
          platform: selectedPlatformName === "all" ? ["instagram", "twitter", "tiktok", "youtube", "linkedin", "facebook"] : [selectedPlatformName],
          lid: selectedListIds,
          iid: selectedInfluencersIds,
          status,
          page: tablePageNo,
          rows: tableRowsPerPage,
          sortCol: "submitted",
          sortOrder,
          taskIds: selectedTasksIds,
          milesstoneIds: []
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },


  /* get timezones filtered by id ("all" included) */
  getTimezoneData: (id) => {
    return axios
      .get(API_HOST + "/timezone/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /* getting time difference between current date and date provided */
  timeAgo(input) {
    const date = input instanceof Date ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat("en");
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
      }
    }
  },

  /* get details of a particular list, given list id */
  getListById: (id) => {
    return axios.get(API_HOST + "/list/id/" + id).then((res) => {
      return res.data;
    });
  },

  /*  main method to render post history timeline in the modal for under-review, approved and rejected posts */
  renderPostHistoryTimeline: (timelineDetails, statusMapping, postHistory) => {
    return postHistory.map((r, i) => {
      let status = "";
      for (let x in statusMapping) {
        if (statusMapping[x] === r.status) {
          status = x;
          break;
        }
      }

      if (status === "" || status === "-" || !status) {
        return "";
      }

      return (
        <TimelineItem key={i}>
          <TimelineOppositeContent sx={{ m: "auto 0" }} align="right" variant="body2" color="text.secondary">
            {new Date(r.createdAt).toDateString()}
            <br />
            {new Date(r.createdAt).toLocaleTimeString()}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot style={{ backgroundColor: timelineDetails[status]["iconColor"] }}>
              {timelineDetails[status]["icon"]}
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography variant="h5" component="span">
                  {timelineDetails[status]["statusDescription"]}
                </Typography>
                <br />
                {r.userName !== "" && <i style={{ pl: "5px", fontSize: "13px", color: "gray" }}>by {r.userName}</i>}
              </Grid>
              {(r.status !== 1 && r.status !== 2 && r.status !== 9) && (
                <Tooltip
                  title={
                    r.lastContent != "" ? (
                      <>
                        <h2 style={{ fontSize: 13 }}>
                          {<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(r.lastContent) }} />}
                        </h2>
                      </>
                    ) : (
                      <h2 style={{ fontSize: 13 }}>{r.comment}</h2>
                    )
                  }
                  placement="top"
                  arrow
                  sx={{ fontSize: "14px" }}
                >
                  <Grid item xs={2}>
                    <Button variant="outlined">
                      <RemoveRedEye />{" "}
                    </Button>
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </TimelineContent>
        </TimelineItem>
      );
    });
  },

  /* updating status of (and adding serial number to) all the elements in the posts array */
  updateStatus: (obj, statusMapping) => {
    let newData = [];
    obj.forEach((item, index) => {
      /* update status */
      const statusId = item["currentStatus"];
      let status = "";
      for (let x in statusMapping) {
        if (statusMapping[x] === statusId) {
          status = x;
          break;
        }
      }
      item["status"] = status;
      newData.push({ sl: index + 1, ...item });
    });
    return newData;
  },

  /* add serial number to all the rows in the table */
  addSerialNumberToTableRows: (obj) => {
    let newData = [];
    obj.forEach((item, index) => {
      newData.push({ sl: index + 1, ...item });
    });
    return newData;
  },
  /* add default profile image in case of wrong profile image URL */
  addDefaultSrc: (e) => {
    e.target.src = PROFILE_IMG;
  },

  /* add default post thumbnail in case of wrong thumbnail image URL */
  addDefaultPostThumbnailImage: (e) => {
    e.target.src = DEFAULT_POST_THUMBNAIL;
  },

  convertDate: (date, type) => {
    let m = moment(date, "YYYY-MM-DD HH:mm:ss");
    if (date === "") {
      return "";
    }
    if (type === "withtime") {
      return m.format("ddd MMM D YYYY, HH:mm:ss");
    } else if (type === "datetime") {
      return moment(date).format("MMM D YYYY, HH:mm:ss");
    } else if (type === "onlydate") {
      return moment(date).format("MMM D YYYY");
    } else if (type === "normal") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return m.format("ddd MMM D YYYY");
  },

  AddDays: (date, duration, type) => {
    return moment(date, "YYYY-MM-DD HH:mm:ss").add(duration, type).format("YYYY-MM-DD 23:59:59");
  },

  getDiscoveryLanguage: () => {
    return axios
      .get(API_HOST + "/discovery/language/linkedin")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getDiscoveryLocation: () => {
    return axios
      .get(API_HOST + "/discovery/location/linkedin")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getCampaignUrlsFilter: (data) => {
    return axios.post(API_HOST + "/link/campaignUrlsFilter", data, optionAxios).then((res) => {
      return res.data;
    });
  },
  formatClassnames: (...args) => [...args].filter(Boolean).join(" "),

  showActionButtons: (statusArray, status) => {
    return statusArray.includes(status);
  },

  rangeOption: (rangeColumn, filterVal) => [
    {
      filter: true,
      filterType: "custom",
      filterList: [],
      customFilterListOptions: {
        render: (v) => {
          let displayText = rangeColumn;
          if (v[0] && v[1] && filterVal) {
            return [`${displayText} - Min : ${v[0]}`, `Max : ${v[1]}`];
          } else if (v[0] && v[1] && !filterVal) {
            return `${displayText} - Min : ${v[0]}, Max : ${v[1]}`;
          } else if (v[0]) {
            return `${displayText} - Min : ${v[0]}`;
          } else if (v[1]) {
            return `${displayText} - Max : ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, "");
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        },
      },
      filterOptions: {
        names: [],
        logic(columnItem, filters) {
          if (filters[0] && filters[1]) {
            return columnItem < filters[0] || columnItem > filters[1];
          } else if (filters[0]) {
            return columnItem < filters[0];
          } else if (filters[1]) {
            return columnItem > filters[1];
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <>
            <FormLabel class="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled css-lbchul-MuiFormLabel-root-MuiInputLabel-root">
              {rangeColumn}
            </FormLabel>
            <FormGroup row>
              <TextField
                label="min"
                type="number"
                value={filterList[index][0] || ""}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                variant="standard"
                style={{ width: "25%", marginRight: "5%" }}
              />
              <TextField
                label="max"
                type="number"
                value={filterList[index][1] || ""}
                onChange={(event) => {
                  if (!filterList[index][0]) {
                    filterList[index][0] = "0";
                  }
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                variant="standard"
                style={{ width: "25%" }}
              />
            </FormGroup>
          </>
        ),
      },
      print: false,
    },
  ],

  getTimePassed: (from_date, to_date) => {
    from_date = moment(from_date);
    to_date = moment(to_date);
    return to_date.from(from_date);
  },

  checkIsObjectEmpty: (obj) => {
    for (let i in obj) return false;
    return true;
  },
  getCampaignTags: (campId) => {
    return axios
      .get(API_HOST + "/post/campaigntags/" + campId)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  addObj: (exitsingObj, newObj) => {
    return exitsingObj.map((obj) => {
      if (!obj.hasOwnProperty(newObj)) {
        return { ...obj, [newObj]: [] };
      }
      return obj;
    });
  },
  getAiFluenceUsers: (data) => {
    return axios.post(`${API_HOST}/access/list`, data, optionAxios).then((res) => {
      return res.data;
    });
  },
  assignAccountManager: (data) => {
    return axios.post(`${API_HOST}/access/manager/changeaccess`, data, optionAxios).then((res) => {
      return res.data;
    });
  },
  getAccountManager: (data) => {
    return axios.post(`${API_HOST}/access/account/getmanager`, data, optionAxios).then((res) => {
      return res.data;
    });
  },
  getKeyByValue: (data, value) => {
    return Object.keys(data).find((key) => data[key] === value);
  },
  getValueByKey: (data, value, match, key) => {
    if (value === undefined || value === "") {
      return "";
    }
    return data[Object.keys(data).find((key) => data[key][match] === value)][key];
  },
  removeElement: (array, element) => {
    var index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  },
  /* get list-wise stats (likes, comments, etc) for analysis */
  getSentimentAnalysisStats: (cid, sd, ed) => {
    return axios
      .post(
        API_HOST + "/sentiments/dailyStats",
        {
          cid: cid,
          startDate: sd,
          endDate: ed,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  handleSaveAs: (event, currentChart, component, name) => {
    let downloadType = event.target.value;
    if (downloadType === "png") utils.downloadImage("png", "png", name, currentChart);
    else if (downloadType === "jpeg") utils.downloadImage("octet-stream", "jpeg", name, currentChart);
    else utils.div2PDF(component, name);
  },
  intArraySum: (array) => {
    if (array.length === 0) {
      return 0;
    }
    return array.reduce((accumulator, currentValue) => accumulator + currentValue);
  },
  // convert timestamp to date & time string (e.g --> 11 Jan 2023, 12:00 AM)
  formatDateTime: (timestamp) => {
    // convert timestamp to date & time string (e.g --> 11 Jan 2023, 12:00 AM)
    const date = new Date(timestamp);
    const locale = navigator.language;
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat(locale, options);
    const formattedDate = formatter.format(date);
    return formattedDate;
  },

  getCampaignScore: async (campId) => {
    return axios.get(API_HOST + "/campaign/score/id/" + campId).then((res) => {
      return res.data;
    });
  },

  getMilestonesByCid: async (campId) => {
    const res = await axios.get(API_HOST + "/milestones/cid/" + campId);
    return res.data;
  },

  createMilestone: async (body) => {
    try {
      const res = await axios.post(API_HOST + "/milestones", body, optionAxios);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },

  createTask: async (payload) => {
    try {
      const res = await axios.post(API_HOST + "/task", payload, optionAxios);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },

  getTasksByCid: async (campId) => {
    const res = await axios.get(API_HOST + "/task/cid/" + campId);
    return res.data;
  },

  updateTaskStatus: async (body) => {
    try {
      const res = await axios.post(API_HOST + "/task/update/status", body, optionAxios);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },

  updateTask: async (payload) => {
    try {
      const res = await axios.post(API_HOST + "/task/update", payload, optionAxios);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },
  getTask: async (tid) => {
    const res = await axios.get(API_HOST + "/task/tid/" + tid);
    return res.data;
  },

  getPerformanceGraph: (campId, from, to, plid) => {
    const optionAxios = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios.post(
      `${API_HOST}/campaignPerformance/id`,
      {
        cid: campId,
        from: from,
        to: to,
        plid: plid,
      },
      optionAxios
    ).then((res) => {
      return res.data;
    });
  },
  /* get engagement and reach by listwise for a particular campaign */
  getEngByListwise: (cid, sd, ed, filter, platformId, postTypes, lids, iids) => {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return axios
      .post(
        API_HOST + "/campaign/engagement/listwise",
        {
          cid: cid,
          from: sd,
          to: ed,
          filterBy: filter,
          plid: platformId,
          postTypes: postTypes,
          lids: lids,
          iids: iids,
        },
        optionAxios
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getPostDetails: (postId) => {
    return axios
      .get(`${API_HOST}/post/details/${postId}`)
      .then((res) => res.data)
      .catch((error) => {
        console.error("Failed to fetch post details:", error);
        throw error;
      });
  },

  getPostHistory: (postId) => {
    return axios
      .get(`${API_HOST}/post/history/${postId}`)
      .then((res) => res.data)
      .catch((error) => {
        console.error("Failed to fetch post history:", error);
        throw error;
      });
  },
};

export default utils;
