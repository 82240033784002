import axios from "axios";
import { API_HOST, INF_API_HOST } from "../../config/main.json";
const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const addInfluencerToDB = (profileUrl, platform, analyze, email, phone, followers) => {
  return axios
    .post(
      INF_API_HOST + "/influencer/add",
      {
        name: profileUrl,
        platform: platform,
        profileUrl: profileUrl,
        analyze: analyze,
        email: email,
        phone: phone,
        followers: followers,
      },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const getInfluencerData = () => {
  return axios.get(INF_API_HOST + "/influencer/profileAnalyzer/list").then((res) => {
    return res.data;
  });
};

const refreshInfluencerStats = (body) =>{
  return axios.post(`${INF_API_HOST}/influencer/profile/refresh`, body, optionAxios.headers).then((res) => {
    return res.data;
  });
}

const getInfluencerContactDetails = (influencer_id) => {
  return axios.get(`${INF_API_HOST}/profile/contact/${influencer_id}`, optionAxios.headers).then((res) => {
    return res.data;
  });
}

const getDetailedInfluencerInformation = (influencer_id) => {
  return axios.get(`${INF_API_HOST}/influencer/${influencer_id}`, optionAxios.headers).then((res) => {
    return res.data;
  });
}

export { addInfluencerToDB, getInfluencerData, refreshInfluencerStats, getInfluencerContactDetails, getDetailedInfluencerInformation };
