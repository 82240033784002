import React, { Component } from "react";
import { API_HOST, INF_API_HOST } from "../../../config/main.json";
import { FormControl, Box, Button, Divider, Grid, TextField, Typography, CircularProgress, Backdrop } from "@mui/material";
import { BadgeTwoTone } from "@mui/icons-material";
import MyPaper from "../../general/MyPaper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import MuiPhoneNumber from "material-ui-phone-number";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { snapshotHeaderIcons, textColor } from "../../campaignAnalysis/snapshot";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class AddInvites extends Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      fields: {},
      errors: {},
      campId: params.get("id"),
      audienceList: [],
      label: "Add Profile or channel url for the individual you want to invite",
      backdropShow: false,
      notification: false,
      message: "",
    };
  }
  postData(formData, e) {
    var platform = formData["platform"];
    var profileUrl = formData["handler"];
    var email = "";
    var phone = "";
    var followers = "0";
    email = formData["email"];
    phone = formData["phoneNumber"];
    followers = formData["followers"];
    var data = {
      name: profileUrl,
      platform: platform,
      profileUrl: profileUrl,
      analyze: false,
      email: email,
      phone: phone,
      followers: followers,
    };
    var optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const self = this;
    let message = "";
    let notification = false;
    axios.post(INF_API_HOST + "/influencer/add", data, optionAxios).then((res) => {
      message = "Influencer is added successfully!";
      if (res.data["status"].includes("Duplicate")) {
        message = "Influencer already exists!";
        if (typeof formData["audienceId"] !== "undefined") {
          let isSuccess = self.addToList(res.data["id"], formData["audienceId"]);
          if (isSuccess) {
            message = "Influencer already exists but linked to List!";
          }
        }
      } else if (res.data["status"].includes("Error")) {
        message = "Something went wrong, Please try again!";
      } else {
        if (typeof formData["audienceId"] !== "undefined") {
          let isSuccess = self.addToList(res.data["id"], formData["audienceId"]);
          if (isSuccess) {
            message = "Influencer is added successfully!";
          }
          notification = true;
          self.setState({ notification, backdropShow: false, message });
          if (this.props.from === "campaign") {
            this.props.onInfluencerAdd({ notification, message });
          }
        }
      }
      notification = true;
      self.setState({ notification, backdropShow: false, message });
      e.target.reset(); // reset form fields after submission

      // self.setState({})
      // window.location.reload();
    });
  }

  addInfluencers(e) {
    let backdropShow = true;
    this.setState({ backdropShow });
    e.preventDefault();
    var body = this.state.fields;
    this.postData(body, e);
  }

  handleChangePlatform(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    if (this.props.from != "campaign") {
      axios.get(API_HOST + "/lists/type/2/" + e.target.value.toLowerCase()).then((res) => {
        const listData = res.data;
        let audienceList = [];
        if (listData !== null) {
          Object(listData).forEach((item, index) => {
            audienceList.push({ sl: index + 1, ...item });
          });
        }
        this.setState({ audienceList });
      });
    } else {
      const body = { cid: this.state.campId, platform: e.target.value.toLowerCase() };
      axios.post(API_HOST + "/list/campaign/platform", body).then((res) => {
        const listData = res.data;
        let audienceList = [];
        if (listData !== null) {
          Object(listData).forEach((item, index) => {
            audienceList.push({ sl: index + 1, ...item });
            audienceList[index]["cname"] = this.props.campaignName;
            audienceList[index]["totalInf"] = audienceList[index]["noOfInfluencers"];
          });
        }
        this.setState({ audienceList });
      });
    }

    if (e.target.value.toLowerCase() === "linkedin" || e.target.value.toLowerCase() === "facebook") {
      this.setState({ label: "Profile Url" });
    }
    this.setState({ fields });
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handlePhoneChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e;
    this.setState({ fields });
  }

  getHandler(handler, platform) {
    if (platform === "instagram") {
      if (handler.includes("https://www.instagram.com/") || handler.includes("www.instagram.com/")) {
        handler = handler.replace("https://", "").replace("www.", "").replace("instagram.com/", "").replace(/\/$/, "");
      }
    } else if (platform === "tiktok") {
      if (handler.includes("https://tiktok.com/") || handler.includes("www.tiktok.com/")) {
        handler = handler.replace("https://", "").replace("www.", "").replace("tiktok.com/@", "").replace(/\/$/, "");
      }
    } else if (platform === "youtube") {
      if (handler.includes("https://youtube.com/") || handler.includes("www.youtube.com/")) {
        handler = handler.replace("https://", "").replace("www.", "").replace("youtube.com/c/", "").replace(/\/$/, "");
      }
    }
    //condition to check if there is any other query after the handler
    if (handler.includes("/?")) {
      handler = handler.substring(0, handler.lastIndexOf("/?"));
    }
    return handler;
  }

  addToList = (influencerId, lid) => {
    var status = true;
    var optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios.post(API_HOST + "/list/influencer/add", { iid: influencerId, lid: lid, type: "direct" }, optionAxios).then((res) => {
      if (res["data"]["status"].includes("Duplicate entry")) {
        status = false;
      } else if (res["data"]["status"] !== "success") {
        status = false;
      }
    });
    return status;
  };

  handleChangeAuto(field, e, values) {
    let fields = this.state.fields;
    fields[field] = "";
    if (values !== null) {
      if (typeof values["id"] !== "undefined") {
        fields[field] = values["id"];
      }
    }
  }

  closeNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    let notification = false;
    this.setState({ notification });
  };

  render() {
    const defaultProps = {
      options: this.state["audienceList"],
      getOptionLabel: (option) => option.name,
    };
    return (
      <form onSubmit={this.addInfluencers.bind(this)}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: "column",
          }}
          open={this.state.backdropShow}
        >
          <CircularProgress color="inherit" />
          <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MyPaper>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Please proceed to include direct influencers by providing the following details:
              </Typography>
              <Divider />
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">Select the platform*</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="platform"
                      value={this.state.platform}
                      label="Select the platform"
                      placeholder="Select the platform for which you want to invite the influencer"
                      onChange={this.handleChangePlatform.bind(this, "platform")}
                      variant="outlined"
                      required={true}
                    >
                      <MenuItem disabled value=" ">
                        <em>Platform</em>
                      </MenuItem>
                      <MenuItem value={"instagram"} selected>
                        Instagram
                      </MenuItem>
                      <MenuItem value={"tiktok"} selected>
                        TikTok
                      </MenuItem>
                      <MenuItem value={"youtube"} selected>
                        YouTube
                      </MenuItem>
                      <MenuItem value={"linkedin"} selected>
                        LinkedIn
                      </MenuItem>
                      <MenuItem value={"facebook"} selected>
                        Facebook
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: "block" }} id="handlerList">
                  <FormControl fullWidth>
                    <TextField
                      id="handler"
                      variant="outlined"
                      name="handler"
                      label={this.state.label}
                      type="url"
                      onChange={this.handleChange.bind(this, "handler")}
                      required={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    refs="email"
                    id="email"
                    onChange={this.handleChange.bind(this, "email")}
                    variant="outlined"
                    label="Add the email of the influencer"
                    sx={{ width: "100%" }}
                    required={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPhoneNumber
                    defaultCountry={"us"}
                    variant="outlined"
                    name="phone_number[]"
                    label="Add Contact Number of the Influencer"
                    sx={{ width: "100%" }}
                    refs="phoneNumber"
                    onChange={this.handlePhoneChange.bind(this, "phoneNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      {...defaultProps}
                      refs="audienceId"
                      id="audienceId"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      options={this.state.audienceList}
                      onChange={this.handleChangeAuto.bind(this, "audienceId")}
                      autoComplete
                      renderInput={(params) => (
                        <>
                        <TextField
                          {...params}
                          label="Which list do you want to add influencer in?"
                          variant="outlined"
                          required
                        />
                      </>
                      )}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          <Typography sx={textColor[this.state.fields["platform"]]}>
                            {snapshotHeaderIcons[this.state.fields["platform"]]}
                          </Typography>
                          &nbsp;&nbsp;
                          <span>{option["cname"] + " - " + option["name"] + " - " + option["totalInf"]}</span>
                        </Box>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    {(this.state.fields["platform"] === "facebook" || this.state.fields["platform"] === "linkedin") && (
                      <TextField
                        id="followers"
                        variant="outlined"
                        name="followers"
                        label="No of Followers"
                        type="number"
                        onChange={this.handleChange.bind(this, "followers")}
                        required={true}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Divider />
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Box sx={{ mt: 4, mr: 4 }}>
                  <Button variant="contained" size="large" type="submit">
                    Add
                  </Button>
                </Box>
              </Grid>
            </MyPaper>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={3000}
          onClose={this.state.closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.state.closeNotification} severity="success" sx={{ width: "100%" }}>
            {" "}
            {this.state.message}{" "}
          </Alert>
        </Snackbar>
      </form>
    );
  }
}
export default AddInvites;
