import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PeopleIcon from "@mui/icons-material/People";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { FaTiktok } from 'react-icons/fa';
const commonBoxStyles = {
  minHeight: "50px",
  minWidth: "150px",
  padding: "auto",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const textColor = {
  total: {
    color: "#33CC99",
  },
  instagram: {
    color: "#BC2A8D",
  },
  twitter: {
    color: "#00ACEE",
  },
  x: {
    color: "#00ACEE",
  },
  tiktok: {
    color: "#000000",
  },
  youtube: {
    color: "#FF0100",
  },
  linkedin: {
    color: "#0a66c2",
  },
  facebook: {
    color: "#1b74e4",
  },
};

export const boxStyles = {
  total: {
    ...commonBoxStyles,
    backgroundColor: textColor.total.color,  
    "&:hover": {
      backgroundColor: "#33CC98",
    },
  },
  instagram: {
    ...commonBoxStyles,
    backgroundColor: textColor.instagram.color,
    "&:hover": {
      backgroundColor: "#ED26AE",
    },
  },
  twitter: {
    ...commonBoxStyles,
    backgroundColor: textColor.twitter.color,
    "&:hover": {
      backgroundColor: "#00B7FF",
    },
  },
  x: {
    ...commonBoxStyles,
    backgroundColor: textColor.x.color,
    "&:hover": {
      backgroundColor: "#00B7FF",
    },
  },
  tiktok: {
    ...commonBoxStyles,
    backgroundColor: textColor.tiktok.color,
    "&:hover": {
      backgroundColor: "#333333", 
    },
  },
  youtube: {
    ...commonBoxStyles,
    backgroundColor: textColor.youtube.color,
    "&:hover": {
      backgroundColor: "#ff3333",  
    },
  },
  linkedin: {
    ...commonBoxStyles,
    backgroundColor: textColor.linkedin.color,
    "&:hover": {
      backgroundColor: "#0a77d5", 
    },
  },
  facebook: {
    ...commonBoxStyles,
    backgroundColor: textColor.facebook.color,
    "&:hover": {
      backgroundColor: "#207ce5",  
    },
  },
};

export const headerStyle = {
  total: {
    ...commonBoxStyles,
    border: "2px solid #33CC99",
    borderRadius: "10px",
  },
  instagram: {
    ...commonBoxStyles,
    border: "2px solid rgb(188, 42, 141)",
    borderRadius: "10px",
  },
  twitter: {
    ...commonBoxStyles,
    border: "2px solid #00ACEE",
    borderRadius: "10px",
  },
  x: {
    ...commonBoxStyles,
    border: "2px solid #00ACEE",
    borderRadius: "10px",
  },
  tiktok: {
    ...commonBoxStyles,
    border: "2px solid #000000",
    borderRadius: "10px",
  },
  youtube: {
    ...commonBoxStyles,
    border: "2px solid #FF0100",
    borderRadius: "10px",
  },
  linkedin: {
    ...commonBoxStyles,
    border: "2px solid #0a66c2",
    borderRadius: "10px",
  },
  facebook: {
    ...commonBoxStyles,
    border: "2px solid #1b74e4",
    borderRadius: "10px",
  },
};

export const ageData = [
  { text: "Below 12", value: "0-12" },
  { text: "18 Years - 24 Years", value: "18-24" },
  { text: "25 Years - 34 Years", value: "25-34" },
  { text: "35 Years - 44 Years", value: "35-44" },
  { text: "45 Years - 54 Years", value: "45-54" },
  { text: "55 Years - 64 Years", value: "55-64" },
  { text: "Above 65 Years", value: "65-100" },
];
export const snapshotHeaderIcons = {
  total: <PeopleIcon sx={{ float: "left" }} />,
  instagram: <InstagramIcon sx={{ float: "left" }} />,
  twitter: <TwitterIcon sx={{ float: "left" }} />,
  x: <TwitterIcon sx={{ float: "left" }} />,
  tiktok: <FaTiktok style={{ width: "20px", height: "20px" }} sx={{ float: "left" }} />, //Custom Icon was not working had to react icon
  youtube: <YouTubeIcon sx={{ float: "left" }} />,
  linkedin: <LinkedIn sx={{ float: "left" }} />,
  facebook: <Facebook sx={{ float: "left" }} />,
};

export const snapshotHeaderIcons2 = {
  instagram: <InstagramIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  twitter: <TwitterIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  x: <TwitterIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  tiktok: <FaTiktok style={{ width: "35px", height: "35px" }} sx={{ float: "left" }} />,
  youtube: <YouTubeIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  linkedin: <LinkedIn sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  facebook: <Facebook sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
};


// campaignStatusColors is objec tthat maps different campaign statuses to their respective colors

export const campaignStatusColors = {
  Live: "#26DE81",
  Draft: "#51A6FF",
  Paused: "#FED330",
  Completed: "#f08995",
  Scheduled: "#f0d689",
  Archived: "#89f0eb",
  "Archived Draft": "#8995f0",
  Deleted: "red",
};

export const statusToNum = {
  Live: 1,
  Draft: 2,
  Paused: 3,
  Scheduled: 4,
  Completed: 5,
  Archived: 6,
  Archived_Draft: 7,
  Deleted: 8,
};

export const groupCodes = {
  populating: { color: "#FED330", id: 1 },
  active: { color: "#26DE81", id: 2 },
  finished: { color: "#4299E1", id: 4 },
  delete: { color: "#4299E1", id: 3 },
  draft: { color: "#1d388c", id: 5 },
};
