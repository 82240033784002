import axios from "axios";
import { API_HOST, INF_API_HOST } from "../../config/main.json";
const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const getInfluencerInformation = (influencer_id, platform, onboarded_type) => {
  return axios.get(`${INF_API_HOST}/profile/social_platform/${influencer_id}/${platform}/${onboarded_type}`).then((res) => {
    return res.data;
  });
};

const getInfluencerDataByInfluencerIds = (ids, platform, lid) => {
  return axios
    .post(`${API_HOST}/influencer/listDetails`, { iids: ids, platform: platform, lid: lid }, optionAxios)
    .then((res) => {
      return res.data;
    });
};

const getInfluencerDetailsByInfluencerId = (id, platform, type) => {
  return axios.get(`${INF_API_HOST}/profile/social_platform/${id}/${platform}/${type}`).then((res) => {
    return res.data;
  });
};

const getAnonymousInfluencers = (listId) => {
  return axios.get(`${API_HOST}/invites/anon/id/${listId}`).then((res) => {
    return res.data;
  });
};

const getDetailedProfileInformation = (influencer_id, platform, onboarded_type) => {
  const urls = [
    `${INF_API_HOST}/profile/social_platform/${influencer_id}/${platform}/${onboarded_type}`,
    `${INF_API_HOST}/profile/basic/${influencer_id}/${onboarded_type}`,
    `${INF_API_HOST}/profile/contact/${influencer_id}`,
    `${INF_API_HOST}/profile/education/${influencer_id}`,
    `${INF_API_HOST}/profile/employment/${influencer_id}`,
    `${INF_API_HOST}/profile/interests/${influencer_id}`,
  ];
  return Promise.all(
    urls.map((url) =>
      fetch(url)
        .then(checkStatus)
        .then(parseJSON)
        .catch((error) => console.log("There was a problem!", error))
    )
  );
};

const getContactDetails = (influencer_id) => {
  const urls = [`${INF_API_HOST}/profile/contact/${influencer_id}`];
  return Promise.all(
    urls.map((url) =>
      fetch(url)
        .then(checkStatus)
        .then(parseJSON)
        .catch((error) => console.log("There was a problem!", error))
    )
  );
};

function checkStatus(response) {
  if (response.ok) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(new Error(response.statusText));
  }
}

function parseJSON(response) {
  return response.json();
}

const updateProfilForInfluencer = (id, name, totalFollowers, profileUrl, type, platform, age_range, gender, location) => {
  var optionAxios = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return axios
    .post(
      `${INF_API_HOST}/profile`,
      { id, name, totalFollowers, profileUrl, type, platform, age_range, gender, location },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const updateContactForInfluencer = (id, type, platform, contactsArr, emailArr) => {
  var optionAxios = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return axios
    .post(`${INF_API_HOST}/profile/contact`, { id, type, platform, contact: contactsArr, email: emailArr }, optionAxios)
    .then((res) => {
      return res.data;
    });
};

const deleteEmailForInfluencer = (id, email, type) => {
  return axios.post(`${INF_API_HOST}/profile/email/delete`, { id, email, type }, optionAxios).then((res) => {
    return res.data;
  });
};

const deleteContactForInfluencer = (id, contact, type) => {
  var optionAxios = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return axios.post(`${INF_API_HOST}/profile/contact/delete`, { id, contact, type }, optionAxios).then((res) => {
    return res.data;
  });
};

const getInfluencerDatalist = async (
  selectedFilter,
  tablePageNo,
  tableRowsPerPage,
  sortCol,
  sortOrder,
  isExport,
  filter,
  startDate,
  endDate,
  isRefreshed,
  mimeType,
  timezone,
  addedFrom,
) => {
  const response = await axios({
    url: `${INF_API_HOST}/influencers/lists`,
    method: "POST",
    data: {
      platform: selectedFilter.toLowerCase(), //for no platform
      page: tablePageNo,
      rows: tableRowsPerPage,
      SortCol: sortCol,
      sortOrder: sortOrder,
      isExport: isExport,
      filter: filter,
      startDate: startDate,
      endDate: endDate,
      isRefreshed: isRefreshed,
      mimeType: mimeType,
      campaignName: "",
      account: 0,
      client: 0,
      timezone: timezone,
      addedFrom: addedFrom,
      status: 1
    },
    headers: optionAxios.headers,
    // responseType: getResponseType(isExport),
  });
  if (response) {
    return response.data;
  }

  return false;
};

export {
  getInfluencerDataByInfluencerIds,
  getAnonymousInfluencers,
  getInfluencerInformation,
  getDetailedProfileInformation,
  getContactDetails,
  updateProfilForInfluencer,
  deleteEmailForInfluencer,
  deleteContactForInfluencer,
  updateContactForInfluencer,
  getInfluencerDetailsByInfluencerId,
  getInfluencerDatalist
};
