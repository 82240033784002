import React, { Component } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { CircularProgress, Box, Tab, Tabs, Typography, FormControl, Tooltip, Snackbar } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import CampaignScore from "../../components/general/CampaignScore";
import CampaignScoreChart from "../../components/general/CampaignScoreChart";
import utils from "../../utils/utils";
import { Stack, Button } from "@mui/material";
import MyPaper from "../../components/general/MyPaper";
import Grid from "@mui/material/Grid";
import CampaignSnapshot from "../../components/campaignAnalysis/CampaignSnapshot";
import TotalEngagementAnalysis from "../../components/campaignAnalysis/TotalEngagementAnalysis";
import PerformanceOverTimeGraph from "../../components/campaignAnalysis/PerformanceOverTimeGraph";
import MuiAlert from "@mui/material/Alert";
import { getAllLists } from "../../services/list/ListDetails";
import EngReachByPostType from "../../components/campaignAnalysis/EngReachByPostType";
import Carousel from "react-material-ui-carousel";
import CampaignFilters from "./CampaignFilters";
import CommonFilters from "./CommonFilters";
import GroupAnalysis from "../../components/campaignAnalysis/GroupAnalysis";
import "../../styles/internal/CampaignAnalytics.css";
import { Link } from "react-router-dom";
import GeneratePptReport from "../../components/generatePpt/GeneratePptReport";
import Widget from "../../utils/Widget";
import { AccessAlarm, AllInclusive, People, Person, PhotoSizeSelectActual, PostAdd } from "@mui/icons-material";
import CampaignActions from "./CampaignActions";
import CalendarToday from '@mui/icons-material/CalendarToday';
import AccessTime from '@mui/icons-material/AccessTime';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import Public from '@mui/icons-material/Public';
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReachByListwise from "../../components/campaignAnalysis/ReachByListwise";
import EngByListWise from "../../components/campaignAnalysis/EngByListWise";
import campaignTabsArray from "../../utils/campaignTabsArray";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import EmptyCampaign from "./EmptyCampaign";
import Loader from "./Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

let campId = 0;
let tabArray;
let backdropShow = true;
let disableCustomDates = true;
const styles = {
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  typography: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    color: "#33CC99",
    borderBottom: "2px solid #33CC99",
    marginBottom: "20px",
  },
  details: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    color: "#33CC99",
    borderBottom: "2px solid #33CC99",
  },
  noData: {
    position: "relative",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "large",
    fontStyle: "italic",
  },
  centerElement: {
    position: "relative",
    left: "50%",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  fetchingDetails: {
    marginTop: "20px",
    paddingLeft: "50px",
  },
};
const chartOptions = {
  maintainAspectRatio: false,
};

let hideOnExport = ["campaign-filters", "save-engagement-analysis", "save-phase-analysis"];

class CampaignAnalysis extends Component {
  constructor(props) {
    super(props);
    this.datePickerRef = React.createRef(Date.now());
    const search = window.location.search;
    const params = new URLSearchParams(search);
    campId = params.get("id");
    tabArray = campaignTabsArray(campId);

    //let source = utils.checkRefreshStatusSource(campId);
    this.state = {
      fields: {},
      scores: {},
      filters: {
        startDate: moment().subtract(6, "month").startOf("month").toDate(),
        endDate: moment().toDate(),
        selectedPlatformName: "all",
        filterBy: "day",
        selectedPlatform: -1,
        selectedListIds: [],
        selectedInfluencersIds: [],
        optionsLists: [],
        optionsInfluencers: [],
      },
      widgetArray: [],
      // preDefinedRanges: {},
      isLoadedCampaignDetails: true,
      selectedPlatform: -1,
      platforms: [],
      campId: campId,
      duration: "tMonth",
      startDate: moment().subtract(6, "month").startOf("month").toDate(),
      endDate: moment().toDate(),
      phases: "",
      filterBy: "day",
      backdropShow: backdropShow,
      disableCustomDates: disableCustomDates,
      totalEngagement: {},
      linkToAddPost: "/campaigns/addPost?id=" + campId,
      message: "",
      refreshStatus: "",
      notification: false,
      disableRefreshButton: false,
      selectedPlatformName: "all",
      loading: true,
      overallLoding: true,
      disabledFilterByWeek: false,
      disabledFilterByMonth: false,
      selectedTab: "0",
      selectedListIds: [],
      selectedInfluencersIds: [],
      hideAdvancedFilters: false,
      expanded: true,
      isCampaignEmpty: false,
    }; /*
      showScore: false,
      showScoreChart: false,
      isLoadedCampaignScore: false,
      pptReportId: 0,
    };
    /*
    
    source?.addEventListener(
      "open",
      (e) => {
        source.onmessage = (event) => {
          let data = JSON.parse(event.data);
          if (data.RefStatus !== "1") {
            if (this.state.refreshStatus === "active") {
              this.setState({
                refreshStatus: "inactive",
                notification: true,
                message: "Data refresh completed!",
                disableRefreshButton: false,
              });
            } else {
              this.setState({
                refreshStatus: "inactive",
              });
            }
          } else {
            this.setState({
              refreshStatus: "active",
              disableRefreshButton: true,
            });
          }
        };
      },
      false
    );*/
    this.handleChangePlatform = this.handleChangePlatform.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleFilterBy = this.handleFilterBy.bind(this);
    this.updateTotalEngagement = this.updateTotalEngagement.bind(this);
    this.changeBackDrop = this.changeBackDrop.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.downloadCampaignData = this.downloadCampaignData.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleCalendarChange = this.handleCalendarChange.bind(this);
    this.handleListChange = this.handleListChange.bind(this);
    this.handleInfluencersChange = this.handleInfluencersChange.bind(this);
    this.showHideAdvancedFilters = this.showHideAdvancedFilters.bind(this);
  }

  async componentDidMount() {
    const isCampaignEmpty = await this.getCampaignDetails(campId);
    if (isCampaignEmpty === true) {
      this.setState({ isCampaignEmpty });
    }
    else {
      this.getNoOfPhases(campId);
      this.getAllPlatforms();
      this.getAllLists(this.state.campId, this.state.selectedPlatform);
      this.getCampaignScore(campId);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    const overallLoding = false;
    this.setState({ overallLoding });
  }

  getAllPlatforms() {
    utils.getPlatforms().then((data) => {
      const platforms = data;
      this.setState({ platforms });
    });
  }

  async getCampaignDetails(campId) {
    const data = await utils.getCampaignSumary(campId);
      const fields = data.overallSummary[0];
      const widgetObject = [
        {
          icon: <Person fontSize="small" />,
          value: fields.influencers,
          name: "Total Accounts",
          info: false,
        },
        {
          icon: <PhotoSizeSelectActual fontSize="small" />,
          value: fields.totalPosts,
          name: "Total Publications",
          info: false,
        },
        {
          icon: <AllInclusive fontSize="small" />,
          value: utils.convertToInternationalNumberSystem(fields.totalEngagements),
          name: "Total engagements",
          info: true,
          text: "Total engagements on all publications across all social networks used in this campaign by all the influencers.",
        },
        {
          icon: <People fontSize="small" />,
          value: utils.convertToInternationalNumberSystem(fields.totalReach),
          name: "Total Reach",
          info: false,
        },

        {
          icon: <PostAdd fontSize="small" />,
          value: fields.pendingPostApproval,
          name: "Publications to Review",
          info: false,
        },
        {
          icon: <AllInclusive fontSize="small" />,
          value: `${fields.averageEngagementRate.toFixed(2)}%`,
          name: "Avg. ER%",
          info: true,
          text: "Average engagement rate of all publications in this campaign.",
        },

      ];

      const campaignMetadata = {
        campaignStart: fields.campaignStart,
        duration: fields.duration,
        brand: fields.brand,
        groupCount: fields.groupCount,
        platforms: fields.platforms.join(", "),
        accountManager: fields.accountManager.name,
        campaignStatus: fields.campaignStatus
      };
      this.setState({
        fields,
        widgetArray: widgetObject,
        isLoadedCampaignDetails: true,
        campaignMetadata: campaignMetadata
      });

      const snapshot = data.snapshot;
      if (snapshot === null) {
        return true;
      }
      else{
        return false;
      }
  

    // this.updatePreDefinedRanges();
  }

  getCampaignScore(campId) {
    utils.getCampaignScore(campId).then((data) => {
      let scores = "";
      if (data && data.length > 0) {
        scores = data[0];
        this.setState({
          scores,
          isLoadedCampaignScore: true,
        });
      }
    });
  }

  async getAllLists(campId, platform) {
    const data = await getAllLists(campId, platform);
    if (data) this.setState({ ...this.state, optionsLists: data });
  }

  getAllInfluencers(lids, platformName) {
    utils.getAllInfluencers(lids, platformName, campId).then((data) => {
      if (data) this.setState({ ...this.state, optionsInfluencers: data });
    });
  }

  changeBackDrop(value) {
    this.setState({ backdropShow: value });
  }

  showHideAdvancedFilters() {
    this.setState({
      hideAdvancedFilters: !this.state.hideAdvancedFilters,
    });
  }

  updateTotalEngagement(value) {
    this.setState({ totalEngagement: value });
  }

  getNoOfPhases(campId) {
    utils.getNoOfphases(campId).then((data) => {
      this.setState({
        phases: data ? data.phases : 0,
        loading: false,
      });
    });
  }

  handleChangePlatform(event) {
    let platform = this.state.platforms.find((e) => e.id === event.target.value);
    if (platform === undefined) {
      platform = { id: 0, name: "all" };
    }
    this.getAllLists(this.state.campId, platform.name);
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        selectedPlatform: event.target.value,
        selectedPlatformName: platform.name,
        selectedListIds: [],
        selectedInfluencersIds: [],
      },
    }));

    this.getAllInfluencers([], platform.name);
  }

  handleFilterBy(event) {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        filterBy: event.target.value,
      },
    }));
  }

  handleListChange(event) {
    let value = event.target.value;
    let allIds = this.state.optionsLists.map((e) => e.id);
    let selected = this.state.filters.selectedListIds.length === this.state.optionsLists.length ? [] : allIds;
    if (value[value.length - 1] === -1) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          selectedListIds: selected,
        },
      }));
      this.getAllInfluencers(selected, this.state.filters.selectedPlatformName);
      return;
    }
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        selectedListIds: value,
      },
    }));
    this.getAllInfluencers(value, this.state.filters.selectedPlatformName);
  }

  handleInfluencersChange(event) {
    let value = event.target.value;
    let allIds = this.state.optionsInfluencers.map((e) => e.Iid);
    let selected = this.state.filters.selectedInfluencersIds.length === this.state.optionsInfluencers.length ? [] : allIds;
    if (value[value.length - 1] === -1) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          selectedInfluencersIds: selected,
        },
      }));
      return;
    }
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        selectedInfluencersIds: value,
      },
    }));
  }

  applyFilters() {
    this.setState({
      startDate: this.state.filters.startDate,
      endDate: this.state.filters.endDate,
      filterBy: this.state.filters.filterBy,
      selectedPlatform: this.state.filters.selectedPlatform,
      selectedPlatformName: this.state.filters.selectedPlatformName,
      selectedListIds: this.state.filters.selectedListIds,
      selectedInfluencersIds: this.state.filters.selectedInfluencersIds,
    });
  }

  hideElementsBeforeCampaignExport() {
    for (let index in hideOnExport) {
      const elementId = hideOnExport[index];
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "none";
      }
    }
  }

  showElementsAfterCampaignExport() {
    for (let index in hideOnExport) {
      const elementId = hideOnExport[index];
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "block";
      }
    }
  }

  downloadCampaignData(event) {
    const downloadAs = event.target.value;
    const today = new Date();
    const filename =
      this.state.fields.name + "-" + today.getDate() + (today.getMonth() + 1) + today.getFullYear() + "." + downloadAs;
    let input = document.getElementById("campaign-analysis");

    this.hideElementsBeforeCampaignExport();

    if (downloadAs === "pdf") {
      var w = input.offsetWidth;
      var h = input.offsetHeight;
      html2canvas(input).then((canvas) => {
        var img = canvas.toDataURL("image/jpeg", 1);
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        var doc = new jsPDF("p", "px", [w, h]);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
        doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.save(filename);
      });
    } else if (downloadAs === "jpeg") {
      html2canvas(input).then((canvas) => {
        var a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = filename;
        a.click();
      });
    } else if (downloadAs === "png") {
      html2canvas(input).then((canvas) => {
        var a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = filename;
        a.click();
      });
    }

    this.showElementsAfterCampaignExport();
  }

  refreshData() {
    this.setState({
      notification: true,
      message: "Data refresh has been initiated!",
      disableRefreshButton: true,
      refreshButtonText: "Initiated...",
    });
    utils.refreshAnalyticsData(this.state.campId).then((data) => {
      if (data.Msg === "success") {
        this.setState({
          notification: true,
          message: "Data refresh successfully started!",
        });
      } else {
        this.setState({
          notification: true,
          message: data.Msg,
          disableRefreshButton: false,
        });
      }
    });
  }

  handleCalendarChange(start, end) {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      },
    }));
    let days = moment(end).diff(moment(start), "days");
    if (days >= 0 && days <= 6) {
      this.setState({
        disabledFilterByWeek: true,
        disabledFilterByMonth: true,
      });
    } else if (days > 6 && days <= 29) {
      this.setState({
        disabledFilterByWeek: false,
        disabledFilterByMonth: true,
      });
    } else {
      this.setState({
        disabledFilterByWeek: false,
        disabledFilterByMonth: false,
      });
    }
  }

  handleStartDate = (date) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: date,
      },
    }));
  };
  handleEndDate = (date) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        endDate: date,
      },
    }));
  };

  showScoreInformation = () => {
    this.setState({ showScore: true });
  };

  hideHelpInformation = () => {
    this.setState({ showScore: false });
  };

  showScoreChart = () => {
    this.setState({ showScoreChart: true });
  };

  hideScoreChart = () => {
    this.setState({ showScoreChart: false });
  };

  renderScoreInformation = () => {
    return (
      <div className="scoreOverlay">
        <CampaignScore
          activated_score={this.state.scores.activated_score}
          contracted_score={this.state.scores.contracted_score}
          dob_score={this.state.scores.dob_score}
          age_score={this.state.scores.age_score}
          gender_score={this.state.scores.gender_score}
          location_score={this.state.scores.location_score}
          location_country_score={this.state.scores.location_country_score}
          kyc_score={this.state.scores.kyc_score}
          username_score={this.state.scores.username_score}
          profile_score={this.state.scores.profile_score}
          post_score={this.state.scores.post_score}
          post_tag_score={this.state.scores.post_tag_score}
          list_post_match={this.state.scores.list_post_match}
          avg_post_score={this.state.scores.avg_post_score}
          avg_post_bonus={this.state.scores.avg_post_bonus}
        />
      </div>
    );
  };

  renderChartInformation = () => {
    return (
      <div className="scoreChartOverlay">
        <CampaignScoreChart />
      </div>
    );
  };

  setPptReportId = (id) => {
    this.setState({ pptReportId: id });
  };
  handleChange = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };


  render() {
    if (!this.state.isLoadedCampaignDetails) {
      return null;
    }
    const closeNotification = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      this.setState({ notification: false });
    };
    const isAllListSelected =
      this.state.optionsLists?.length > 0 && this.state.filters.selectedListIds.length === this.state.optionsLists.length;
    const isAllInfluencerSelected =
      this.state.optionsInfluencers?.length > 0 &&
      this.state.filters.selectedInfluencersIds.length === this.state.optionsInfluencers.length;
   
    return (
      <>
        <CampaignActions name={this.state.fields.name} id={campId} />
        <br />
        <CustomTabs tabArray={tabArray} selectedTab={"0"} />

        {this.state.overallLoding ? (
          <Loader />
        ) : (
          this.state.isCampaignEmpty === true ? (
            <div style={{ marginTop: '20px', overflow: 'hidden' }}>
              <EmptyCampaign />
            </div>
          ) : (
            <div id="campaign-analysis" style={{ marginTop: '20px', overflow: 'hidden' }}>
              <>
                {this.state.loading ? (
                  <CircularProgress
                    style={{
                      position: "relative",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    color="success"
                  />
                ) : (

                  // Campaign Details
                  <>
                    <Accordion
                      expanded={this.state.expanded}
                      onChange={this.handleChange}
                      sx={{
                        '&.Mui-expanded': {
                          margin: 'auto'
                        }
                      }}  >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="campaign-details-content"
                        id="campaign-details-header"
                        sx={{
                          '& .MuiAccordionSummary-expandIconWrapper': {
                            position: 'absolute',
                            right: '16px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                          }
                        }}
                      >
                        <Stack direction="row" justifyContent="start">
                          <Typography variant="h4" sx={styles.details}>
                            Campaign Details
                          </Typography>
                        </Stack>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={2} style={{ padding: "20px" }}>
                          {/* Widget Section */}
                          <Grid item xs={6}>
                            {this.state.isLoadedCampaignDetails && (
                              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 6 }}>
                                {this.state.widgetArray.map((widget, index) => (
                                  <Grid item key={index} xs={1} sm={2} md={3} fontSize={12}>
                                    <Widget widget={widget} />
                                  </Grid>
                                ))}
                              </Grid>
                            )}
                          </Grid>
                          {/* Metadata and Campaign Score Section - Take up 3 out of 12 columns */}
                          <Grid item xs={12} sm={6}>
                            <div style={{
                              display: 'grid',
                              gridTemplateColumns: 'auto auto',
                              backgroundColor: '#D9D9D9',
                              padding: '20px',
                              borderRadius: '10px',
                              boxShadow: '0 4px 8px rgba(0,0,0,0.12)',
                              gap: '10px',
                              fontSize: '14px',
                            }}>
                              {[
                                {
                                  label: "Channels",
                                  value: this.state.campaignMetadata?.platforms ?
                                    this.state.campaignMetadata.platforms.split(', ').map(platform => {
                                      if (!snapshotHeaderIcons[platform.toLowerCase()]) return null; // If no icon exists, return null
                                      return (
                                        <Typography sx={{ ...textColor[platform.toLowerCase()] }}>
                                          {React.cloneElement(snapshotHeaderIcons[platform.toLowerCase()], {
                                            style: { fontSize: platform.toLowerCase() === 'tiktok' ? 'calc(1rem + 0.1vw)' : 'calc(1.3rem + 0.1vw)' }
                                          })}
                                        </Typography>
                                      );
                                    }) : null,
                                  icon: <Public fontSize="small" />
                                },
                                { label: "Campaign Start", value: this.state.campaignMetadata?.campaignStart, icon: <CalendarToday fontSize="small" /> },
                                { label: "Duration", value: this.state.campaignMetadata?.duration, icon: <AccessTime fontSize="small" /> },
                                { label: "Campaign Status", value: this.state.campaignMetadata?.campaignStatus, icon: <CheckCircleOutline fontSize="small" /> },
                                { label: "Brand", value: this.state.campaignMetadata?.brand, icon: <BusinessCenter fontSize="small" /> },
                                { label: "No. of Lists", value: this.state.campaignMetadata?.groupCount, icon: <FormatListBulleted fontSize="small" /> },
                                { label: "Account Manager", value: this.state.campaignMetadata?.accountManager, icon: <Person fontSize="small" /> }
                              ].map((item, index) => (
                                <div key={index} style={{
                                  display: 'contents',
                                }}>
                                  <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    {item.icon}
                                    <strong>{item.label}:</strong>
                                  </span>
                                  <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    justifyContent: 'flex-start',
                                  }}>
                                    {Array.isArray(item.value) ?
                                      item.value.map((iconComponent) => iconComponent) : <span>{item.value}</span>
                                    }
                                  </span>
                                </div>
                              ))}
                            </div>
                            {/* Campaign Data Quality Score Display */}
                            {this.state.isLoadedCampaignScore && (
                              <div style={{
                                backgroundColor: '#D9D9D9',
                                padding: '15px',
                                borderRadius: '10px',
                                marginTop: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                              }}>
                                <div style={{ flexBasis: '100px', flexGrow: 0, flexShrink: 0 }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" style={{ width: '100px', height: '100px' }}>
                                    <g filter="url(#filter0_d_2735_18650)">
                                      <circle cx="50" cy="50" r="50" fill="#FEC32E" />
                                      <circle cx="50" cy="50" r="49.5" stroke="#000000" />
                                      <text
                                        x="50"
                                        y="55"
                                        textAnchor="middle"
                                        alignmentBaseline="middle"
                                        style={{
                                          fontSize: '20px',
                                          fontWeight: 'bold',
                                          fill: '#000000'
                                        }}
                                      >
                                        {Math.round(this.state.scores.total_score)}/100
                                      </text>
                                    </g>
                                    <defs>
                                      <filter
                                        id="filter0_d_2735_18650"
                                        x="0"
                                        y="0"
                                        width="130"
                                        height="130"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                      >
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          result="hardAlpha"
                                        />
                                        <feOffset dx="2" dy="4" />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="" result="shape" />
                                      </filter>
                                    </defs>
                                  </svg>
                                </div>
                                <div style={{ marginLeft: '20px', textAlign: 'center', flex: 1 }}>
                                  <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bold' }}>
                                    {this.state.scores.grade} {this.state.scores.grade_text}
                                    {/* Question mark icon next to grade text */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                      style={{ marginLeft: '10px', marginBottom: '1px' }}
                                      onMouseOver={this.showScoreInformation}
                                      onMouseOut={this.hideHelpInformation}
                                    >
                                      <circle cx="7.5" cy="7.5" r="7" fill="none" stroke="#757575" strokeWidth="1" />
                                      <text x="7.5" y="11" textAnchor="middle" style={{ fontSize: '10px', fill: '#757575', fontWeight: 'bold' }}>?</text>
                                    </svg>
                                  </div>
                                  <div style={{
                                    color: '#007DFF',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    marginTop: '10px'
                                  }}>Campaign Data Quality Score</div>
                                </div>
                                {this.state.showScore && (
                                  <div style={{
                                    position: 'absolute',
                                    left: '5vw',
                                    bottom: '20vh',
                                    width: '200px',
                                    borderRadius: '5px',
                                  }}>
                                    {this.state.showScore && this.renderScoreInformation()}
                                    {this.state.showScoreChart && this.renderChartInformation()}
                                  </div>
                                )}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
                <br />
                <div style={{ background: 'white' }}>

                  {/* Campaign Filters  */}
                  <div id="campaign-filters" style={{ background: 'none', boxShadow: 'none' }}>
                    <CampaignFilters
                      name={this.state.fields.name}
                      id={this.state.campId}
                      selectedPlatform={this.state.filters.selectedPlatform}
                      selectedPlatformName={this.state.filters.selectedPlatformName}
                      platforms={this.state.platforms}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      filterBy={this.state.filters.filterBy}
                      disabledFilterByWeek={this.state.disabledFilterByWeek}
                      disabledFilterByMonth={this.state.disabledFilterByMonth}
                      hideAdvancedFilters={this.state.hideAdvancedFilters}
                      selectedListIds={this.state.filters.selectedListIds}
                      selectedInfluencersIds={this.state.filters.selectedInfluencersIds}
                      disableRefreshButton={this.state.disableRefreshButton}
                      isAllListSelected={isAllListSelected}
                      optionsLists={this.state.optionsLists}
                      isAllInfluencerSelected={isAllInfluencerSelected}
                      optionsInfluencers={this.state.optionsInfluencers}
                      handleChangePlatform={this.handleChangePlatform}
                      handleCalendarChange={this.handleCalendarChange}
                      handleFilterBy={this.handleFilterBy}
                      handleListChange={this.handleListChange}
                      handleInfluencersChange={this.handleInfluencersChange}
                      datePickerRef={this.datePickerRef}
                      applyFilters={this.applyFilters}
                      setState={this.setState}
                      showHideAdvancedFilters={this.showHideAdvancedFilters}
                      hideFilterBy={false}
                      hidePlatform={false}
                      hideInfluencers={true}
                      hideTasks={true}
                      hideRange={false}
                      alignWidth={"50%"}
                      handleDownloadData={this.downloadCampaignData}
                      currentStatus={this.state.fields.current_status}
                    />
                  </div>
                  {/* Campaign Snapshot */}
                  <CampaignSnapshot
                    campId={this.state.campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    filterBy={this.state.filterBy}
                    duration={this.state.duration}
                    updateTotalEngagement={this.updateTotalEngagement}
                    changeBackDrop={this.changeBackDrop}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    platforms={this.state.platforms}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                  />
                  {/* Performance Over Time Graph */}
                  <PerformanceOverTimeGraph
                    campId={campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    filterBy={this.state.filterBy}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    platforms={this.state.platforms}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                  />
                  <TotalEngagementAnalysis
                    campId={this.state.campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    platforms={this.state.platforms}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    filterBy={this.state.filterBy}
                    changeBackDrop={this.changeBackDrop}
                    chartOptions={chartOptions}
                    totalEngagement={this.state.totalEngagement}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                  />

                  <GroupAnalysis
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    filterBy={this.state.filterBy}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    platforms={this.state.platforms}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                  />
                  <EngByListWise
                    campId={this.state.campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    filterBy={this.state.filterBy}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    platforms={this.state.platforms}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                  />
                  <ReachByListwise
                    campId={this.state.campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    filterBy={this.state.filterBy}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    platforms={this.state.platforms}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                  />
                  <EngReachByPostType
                    campId={this.state.campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    platforms={this.state.platforms}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    filterBy={this.state.filterBy}
                    changeBackDrop={this.changeBackDrop}
                    chartOptions={chartOptions}
                    totalEngagement={this.state.totalEngagement}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                    type="reach"
                  />
                  <EngReachByPostType
                    campId={this.state.campId}
                    parentStyle={styles}
                    startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                    endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                    platforms={this.state.platforms}
                    selectedPlatform={this.state.selectedPlatform}
                    selectedPlatformName={this.state.selectedPlatformName}
                    filterBy={this.state.filterBy}
                    changeBackDrop={this.changeBackDrop}
                    chartOptions={chartOptions}
                    totalEngagement={this.state.totalEngagement}
                    selectedListIds={this.state.selectedListIds}
                    selectedInfluencersIds={this.state.selectedInfluencersIds}
                    type="engagement"
                  />
                </div>
              </>
              <Snackbar
                open={this.state.notification}
                autoHideDuration={3000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert onClose={closeNotification} severity="success" sx={{ width: "100%" }}>
                  {this.state.message}
                </Alert>
              </Snackbar>
            </div>
          )
        )}
      </>
    );


  }
}

export default CampaignAnalysis;
