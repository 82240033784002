import { Navigate } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import SigninLayout from "../pages/signin/SigninLayout";
import SignIn from "../pages/signin/SignIn";
import Dashboard from "../pages/account/Dashboard";
import Campaigns from "../pages/account/Campaigns";
import NewCampaign from "../pages/account/NewCampaign";
import AccountLayout from "../pages/account/AccountLayout";
import EditCampaign from "../pages/account/EditCampaign";
import ArchivedCampaigns from "../pages/account/ArchivedCampaigns";
import Account from "../pages/account/Account";
import NewAccount from "../pages/account/NewAccount";
import EditAccount from "../pages/account/EditAccount";
import EditBrand from "../pages/account/EditBrand";
import ArchivedAccount from "../pages/account/ArchivedAccount";
import Invites from "../pages/account/Invites";
import Influencers from "../pages/account/Influencers";
import ViewInfluencers from "../pages/account/ViewInfluencer";
import SocialInfluencers from "../pages/account/SocialInfluencer";
import List from "../pages/account/List";
import CreateList from "../pages/account/CreateList";
import AddInfluencers from "../pages/account/AddInfluencers";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import CampaignAnalysis from "../pages/account/CampaignAnalysis";
import AccountDashbord from "../pages/account/AccountDashbord";
import AddPost from "../components/account/details/AddPost";
import InfluencerAnalysis from "../components/campaignAnalysis/influencers/InfluencerAnalysis";
import ArchivedList from "../pages/account/ArchivedList";
import DeletedList from "../pages/account/DeletedList";
import Posts from "../components/posts/Posts";
//import ApprovedPosts from "../components/posts/ApprovedPosts";
import ListAnalysis from "../pages/account/ListAnalysis";
import LinkAnalysis from "../components/linkAnalysis/LinkAnalysis";
import Contracts from "../pages/account/Contracts";
import ContractTemplates from "../pages/account/ContractTemplates";
import NewTemplate from "../pages/account/NewTemplate";
import EditTemplate from "../pages/account/EditTemplate";
import SendContract from "../pages/account/SendContract";
import Discovery from "../components/discovery/Discovery";
import ListDetails from "../components/list/ListDetails";
import PostAnalysis from "../components/campaignAnalysis/posts/PostAnalysis";
import Sentiments from "../pages/account/Sentiments";
import Translate from "../pages/account/Translate";
import Profile from "../components/profile/Profile";
import LinkManageUrls from "../components/linkAnalysis/LinkManageUrls";
import LinkCreateShortUrls from "../components/linkAnalysis/LinkCreateShortUrls";
import ProfileAnalyze from "../pages/account/ProfileAnalyze";
import IMAIDiscovery from "../components/imaiDiscovery/Discovery";
import CampaignBrief from "../pages/account/CampaignBrief";
import AnalyzeProfile from "../components/imaiDiscovery/components/AnalyzeProfile/AnalyzeProfile";
import Analyze from "../components/imaiDiscovery/components/AnalyzeProfile/Analyze";
import InboundLeads from "../pages/account/InboundLeads";
import EmpowrQueries from "../pages/account/EmpowrQueries";
import EmpowrQueriesResolved from "../pages/account/EmpowrQueriesResolved";
import SentimentAnalysis from "../pages/account/SentimentAnalysis";
import UserAccess from "../pages/account/UserAccess";
import DisabledUserAccess from "../pages/account/DisabledUserAccess";
import ExportList from "../components/imaiDiscovery/FilterComponents/ExportList";
import UserLogs from "../pages/account/UserLogs";
import AppLinks from "../pages/account/AppLinks";
import ManageAppLinks from "../pages/account/ManageAppLinks";
import ClientAccess from "../pages/account/ClientAccess";
import DisabledClientAccess from "../pages/account/DisabledClientAccess";
import Tasks from "../pages/account/Tasks";
import DeletedCampaigns from "../pages/account/DeletedCampaigns";
import ReportAnalysis from "../components/campaignAnalysis/reports/ReportAnalysis";
import Brand from "../pages/account/Brands";
import ArchivedBrand from "../pages/account/ArchivedBrand";
import DeletedBrand from "../pages/account/DeletedBrand";
import BrandDetails from "../pages/account/BrandDetails";
import DeletedAccount from "../pages/account/DeletedAccount";
import NewBrand from "../pages/account/NewBrand";
import DraftPostAnalysis from "../components/campaignAnalysis/posts/DraftPostAnalysis";
//import DiscardedDrafts from "../components/posts/DiscardedDrafts";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/404", element: <NotFound /> },
      { path: "/", element: <Home /> },
      { path: "/*", element: <Navigate to="/404" /> },
      {
        path: "/",
        element: <AccountLayout />,
        children: [
          { path: "/", element: <Dashboard /> },
          { path: "/campaigns", element: <Campaigns /> },
          { path: "/campaigns/archived", element: <ArchivedCampaigns /> },
          { path: "/campaigns/deleted", element: <DeletedCampaigns /> },
          { path: "/campaigns/add", element: <NewCampaign /> },
          { path: "/campaigns/edit", element: <EditCampaign /> },
          { path: "/campaigns/viewdetails", element: <EditCampaign/>},
          { path: "/campaigns/addPost", element: <AddPost /> },
          { path: "/campaigns/analysis", element: <CampaignAnalysis /> },
          { path: "/account/dashboard", element: <AccountDashbord /> },
          { path: "/campaigns/influencers", element: <InfluencerAnalysis /> },
          { path: "/campaigns/postAnalysis", element: <PostAnalysis /> },
          { path: "/campaigns/listAnalysis", element: <ListAnalysis /> },
          { path: "/campaigns/linkAnalysis", element: <LinkAnalysis /> },
          { path: "/campaigns/linkAnalysis/urls", element: <LinkManageUrls /> },
          {
            path: "/campaigns/linkAnalysis/urls/create",
            element: <LinkCreateShortUrls />,
          },
          // { path: "/campaigns/audience", element: <AudienceReportSelector /> },
          { path: "/accounts", element: <Account /> },
          { path: "/brands", element: <Brand /> },
          // { path: "/BrandTable", element: <BrandTable /> },
          { path: "/brands/archived", element: <ArchivedBrand /> },
          { path: "/brands/deleted", element: <DeletedBrand /> },
          { path: "/brand/details", element: <BrandDetails /> },
          { path: "/accounts/archived", element: <ArchivedAccount /> },
          { path: "/accounts/deleted", element: <DeletedAccount /> },
          { path: "/accounts/add", element: <NewAccount /> },
          { path: "/brands/add", element: <NewBrand /> },
          { path: "/accounts/edit", element: <EditAccount /> },
          { path: "/brands/edit", element: <EditBrand /> },
          { path: "/influencers/", element: <Invites /> },
          { path: "/discovery/", element: <Influencers /> },
          { path: "/discovery/view", element: <ViewInfluencers /> },
          { path: "/discovery/social/view", element: <SocialInfluencers /> },
          { path: "/list/", element: <List /> },
          { path: "/list/details", element: <ListDetails /> },
          { path: "/influencers/add", element: <AddInfluencers /> },
          { path: "/list/archived", element: <ArchivedList /> },
          { path: "/list/deleted", element: <DeletedList /> },
          { path: "/posts", element: <Posts /> },
          {path: "/posts/draft", element: < DraftPostAnalysis/>},
          //{ path: "/posts/approved", element: <ApprovedPosts /> },
          //{ path: "/posts/discarded", element: <DiscardedDrafts /> },
          { path: "/campaigns/listAnalysis", element: <ListAnalysis /> },
          { path: "/campaigns/createList", element: <CreateList /> },
          { path: "/campaigns/contracts", element: <Contracts /> },
          { path: "/campaigns/contracts/send", element: <SendContract /> },
          {
            path: "/campaigns/contracts/templates",
            element: <ContractTemplates />,
          },
          {
            path: "/campaigns/contracts/templates/add",
            element: <NewTemplate />,
          },
          {
            path: "/campaigns/contracts/templates/edit",
            element: <EditTemplate />,
          },
          { path: "/tools/sentiments", element: <Sentiments /> },
          { path: "/tools/translate", element: <Translate /> },
          { path: "/tools/profileAnalyze", element: <ProfileAnalyze /> },
          { path: "/bdTools/campaignBrief", element: <CampaignBrief /> },
          { path: "/bdTools/inboundLeads", element: <InboundLeads /> },
          { path: "/bdTools/empowrQueries", element: <EmpowrQueries /> },
          {
            path: "/bdTools/empowrQueries/resolved",
            element: <EmpowrQueriesResolved />,
          },
          { path: "/linkedin/discovery", element: <Discovery /> },
          { path: "/linkedin/profile", element: <Profile /> },
          { path: "/advance/discovery", element: <IMAIDiscovery /> },
          {
            path: "/advance/analyze",
            element: <Analyze />,
          },
          { path: "/advance/discovery/exports", element: <ExportList /> },
          {
            path: "/advance/analyze/:platform/:id",
            element: <AnalyzeProfile />,
          },
          {
            path: "/campaigns/sentiment/analysis",
            element: <SentimentAnalysis />,
          },
          { path: "/userAccess/aifluence", element: <UserAccess /> },
          { path: "/userAccess/aifluence/disabled", element: <DisabledUserAccess /> },
          { path: "/userAccess/empowr", element: <ClientAccess /> },
          { path: "/userAccess/empowr/disabled", element: <DisabledClientAccess /> },
          { path: "/userLogs", element: <UserLogs /> },
          { path: "/campaigns/Applinks", element: <AppLinks /> },
          { path: "/campaigns/Applinks/manage", element: <ManageAppLinks /> },
          { path: "/campaigns/tasks", element: <Tasks /> },
          { path: "/campaigns/reports", element: <ReportAnalysis /> },
        ],
      },
    ],
  },
  {
    path: "signin",
    element: <SigninLayout />,
    children: [{ path: "/signin", element: <SignIn /> }],
  },
];

const memberRestricted = ["/userAccess/aifluence", "/userAccess/aifluence/disabled", "/campaigns/add", "/campaigns/edit","/campaigns/viewdetails"];

const managerRestricted = [];

export default routes;

export { memberRestricted, managerRestricted };
