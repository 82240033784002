import { Alert, Box, Button, Chip, Grid, Snackbar, Stack, Typography } from "@mui/material";
import slides from "../../../common/googleSlides.png";
import React from "react";
import utils from "../../../utils/utils";
import GeneratePptReport from "../../generatePpt/GeneratePptReport";
import axios from "axios";
import { API_HOST } from "../../../config/main.json";
import { Download } from "@mui/icons-material";
import FileSaver from "file-saver";
import CampaignActions from "../../../pages/account/CampaignActions";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/features/snackbar/snackbarSlice";
import CustomTabs from "../../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../../utils/campaignTabsArray";

const ReportAnalysis = () => {
  const [state, setState] = React.useState({
    pptReportId: 0,
    campaignName: "",
  });
  const [reportHistory, setReportHistory] = React.useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let campId = params.get("id");
  let accId = params.get("accId"); 
  let  tabArray = campaignTabsArray(campId, accId);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getCampaignInfo();
    getReportHistory(campId);
  }, [campId, state.pptReportId]);

  const getCampaignInfo = () => {
    utils.getCampaignData(campId).then((data) => {
      setState({ ...state, campaignName: data.name });
    });
  };
  const getReportHistory = (id) => {
    axios.get(API_HOST + "/report/history/" + id).then((res) => {
      const data = res.data || [];
      setReportHistory(data);
    });
  };

  const downloadPpt = (title, id) => {
    dispatch(
      openSnackbar({
        message: "Please wait... We are downloading your ppt",
        severity: "info",
      })
    );
    axios
      .get(API_HOST + "/report/download/" + id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        responseType: "blob",
      })
      .then((res) => {
        dispatch(
          openSnackbar({
            message: "PPT downloaded successfully",
            severity: "success",
          })
        );
        const data = res.data || [];
        FileSaver(data, title + ".pptx");
      });
  };

  return (
    <>
      <CampaignActions name={state.campaignName} id={campId} />
      <br />
      <CustomTabs tabArray={tabArray} campId={campId} accId={accId} selectedTab={"9"} />
      <Stack direction="row" justifyContent="space-between">
        <Box></Box>
        <Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ mr: "20px", mt: "20px" }}
            startIcon={
              <img
                src={slides}
                alt="slides"
                style={{
                  color: "white",
                  height: "20px",
                  width: "20px",
                }}
              />
            }
            onClick={() => {
              setState({ ...state, pptReportId: campId });
            }}
          >
            Generate Report
          </Button>
        </Box>
      </Stack>
      <Box m={3} sx={{ minHeight: "50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {reportHistory.length === 0 ? (
          <Box textAlign="center" my={4}>
          <Typography variant="h4" color="textSecondary">
            No reports available yet.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Generate a report to get insights on your campaign’s performance.
          </Typography>
        </Box>
        ) : (
          <div style={{ maxHeight: "50vh", overflowY: "auto", width:'100%' }}>
            {reportHistory.map((report, index) => (
              <Grid container>
                <Grid item sm={11}>
                  <div style={{ padding: "8px" }}>
                    <Box
                      sx={{
                        "&:hover": {
                          bgcolor: "#eeeeee",
                        },
                        padding: "10px 20px",
                        cursor: "pointer",
                        height: "68px",
                      }}
                      onClick={() => window.open(report.link, "_blank", "noreferrer")}
                    >
                      <div>
                        <span style={{ fontSize: "18px" }}>{report.title}</span>
                        {index === 0 && (
                          <Chip
                            sx={{
                              ml: "6px",
                              transform: "scale(0.8) translateY(-4px)",
                              bgcolor: "#5682f0",
                              borderRadius: "12px",
                              fontSize: "16px",
                            }}
                            color="primary"
                            label="New"
                          />
                        )}
                      </div>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <div style={{ fontSize: "14px", color: "gray" }}>
                            {"Built on " + utils.convertDate(report.date, "datetime") + " GMT "}
                          </div>
                        </Grid>
                        <Grid item>
                          <div style={{ fontSize: "14px", color: "gray" }}>
                            {"Using " + utils.convertDate(report.dataUpdateTime, "onlydate") + " data"}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Grid>
                <Grid item sm={1}>
                  <div style={{ padding: "8px" }}>
                    <Box
                      sx={
                        report.link === ""
                          ? {
                              bgcolor: "#f9f9f9",
                              height: "68px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                          : {
                              bgcolor: "#f9f9f9",
                              "&:hover": {
                                bgcolor: "#eeeeee",
                              },
                              cursor: "pointer",
                              height: "68px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                      }
                      onClick={() => {
                        const sepLink = report.link.split("/");
                        const id = sepLink[sepLink.length - 1];
                        downloadPpt(report.title, id);
                      }}
                    >
                      <Download fontSize="medium" sx={report.link === "" && { color: "lightgray" }} />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            ))}
          </div>
        )}
      </Box>
      <GeneratePptReport
        pptReportId={state.pptReportId}
        needPastReports={false}
        setPptReportId={(id) => {
          setState({ ...state, pptReportId: id });
        }}
      />
    </>
  );
};

export default ReportAnalysis;