import React, { useState, useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";
import {
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { BarChart } from "../../common/chart";


const TotalEngagementAnalysis = (props) => {
  const chartRef = useRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [totalEngagement, setTotalEngagement] = React.useState([]); //total engagement of all influencer posts
  const [totalEngagementTime, setTotalEngagementTime] = React.useState([]);
  const [platforms, setPlatforms] = React.useState([]);

  useEffect(() => {
    fetchInitialData();
  }, [
    props.campId,
    props.startDate,
    props.endDate,
    props.filterBy,
    props.selectedPlatform,
    props.selectedListIds,
    props.selectedInfluencersIds,
  ]);

  const fetchInitialData = async () => {
    await fetchTotalEngagementData(
      props.campId,
      props.startDate,
      props.endDate,
      props.filterBy,
      props.selectedPlatform,
      props.selectedListIds,
      props.selectedInfluencersIds
    );
  };

  const fetchTotalEngagementData = async (
    campId,
    startDate,
    endDate,
    filterBy,
    platformId,
    listIds,
    influencerIds
  ) => {
    if (listIds.length === 0) listIds = [-1];
    if (influencerIds.length === 0) influencerIds = [-1];
    setLoading(true);
    try {
      const engagement = await utils.getTotalEngagementData(
        campId,
        startDate,
        endDate,
        filterBy,
        platformId,
        [-1],
        listIds,
        influencerIds
      );
      if (engagement.status === 200) {
        if (engagement.data.TotalEngagement !== null) {
          createEngagementSummaryArray(engagement.data.TotalEngagement);
        } else {
          createEngagementSummaryArray({});
        }
        //platform wise engagement graph
        if (engagement.data.Summary !== null) {
          const platforms = Object.keys(engagement.data.Summary);
          setPlatforms(platforms);
        } else {
          setPlatforms([]);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
    } finally {
      setLoading(false);
    }
  };


  const createEngagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const platforms = Object.keys(summarySplit);
      let event = createPlatformSplitArray(platforms, summarySplit, true);
      setTotalEngagementTime(event.finalDateArray);
      setTotalEngagement(event.seriesArray);
    }
  };

  const createPlatformSplitArray = (summaryObject, summarySplit, color) => {
    let dateArray = [];
    let seriesArray = [];
    summaryObject.forEach((platform) => {
      dateArray.push(...Object.keys(summarySplit[platform]));
    });

    summaryObject.forEach((platform) => {
      const object = {
        name: platform,
        type: "bar",
        showBackground: false,
        data: [],
      };
      dateArray.forEach((date) => {
        if (summarySplit[platform][date]) {
          object.data.push(summarySplit[platform][date]);
        } else {
          object.data.push(0);
        }
      });
      seriesArray.push(object);
    });
    const finalDateArray = [...new Set(dateArray)];
    finalDateArray.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    return { finalDateArray, seriesArray };
  };

  const prepareOption = () => {
    return BarChart(totalEngagementTime, true, totalEngagement, platforms, ["line", "bar", "stack"]);
  };

  return (
    <MyPaper sx={{ marginTop: "20px", boxShadow: "none" }}>
      {loading ? (
        <>
          <CircularProgress
            style={{
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            color="success"
          />
          <div
            style={{
              ...props.parentStyle.centerElement,
              ...props.parentStyle.fetchingDetails,
            }}
          >
            Fetching details...
          </div>
        </>
      ) : (
        <>
          <Stack direction="row" justifyContent="end">
            <Typography
              variant="h4"
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#1E2022",
                alignSelf: "flex-start",
                marginRight: "auto",
                display: "flex",
              }}
            >
              Overall Engagement By Platform
            </Typography>
          </Stack>
          <div style={{ height: "50vh", width: "100%", marginTop: '2vw' }}>
            {totalEngagementTime?.length > 0 && totalEngagement?.length > 0 ? (
              <ReactEcharts ref={chartRef} option={prepareOption()} style={{ height: "100%", width: "100%" }} />
            ) : (
              <p style={props.parentStyle.noData}>No Graph found</p>
            )}
          </div>
        </>
      )}
    </MyPaper>
  );
};

export default TotalEngagementAnalysis;
